import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Stack,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { HiChevronRight } from 'react-icons/hi';
import { useSnackbar } from 'notistack';
import { analytics } from 'src/helpers';
import { useAnalytics } from 'src/helpers/analytics';
import {
  IconHelpfulnessState,
  RecommendationData,
} from './recommendation-accordion';

type RecommendationFeedbackModalProps = {
  open: boolean;
  onClose: () => void;
  updateHelpfulness: (helpfulness: IconHelpfulnessState) => void;
  data: RecommendationData;
};

export default function RecommendationFeedbackModal({
  open,
  onClose,
  updateHelpfulness,
  data,
}: RecommendationFeedbackModalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useAnalytics();

  const otherValue = 'Other';
  const options = [
    "This recommendation isn't relevant to my fleet",
    "This recommendation isn't actionable",
    "I don't understand this recommendation",
    otherValue,
  ];

  const [feedbackDetails, setFeedbackDetails] = useState('');
  const [otherFeedback, setOtherFeedback] = useState('');
  const [radioValue, setRadioValue] = useState('');

  const onSubmit = () => {
    updateHelpfulness(IconHelpfulnessState.Unhelpful);
    onClose();
    const feedback = radioValue === otherValue ? otherFeedback : radioValue;
    trackEvent({
      event: analytics.EventTrack.RecommendationFeedback,
      properties: {
        helpful: IconHelpfulnessState.Unhelpful,
        recommendation: data.title,
        feedback,
        details: feedbackDetails,
        id: data.id,
      },
    });
    enqueueSnackbar('Thanks for your feedback!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className="w-full p-8 pt-6 text-xs">
        <IconButton
          size="small"
          className="absolute text-gray-500 top-4 right-6"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Box className="text-xl mb-2.5 font-semibold">Give us feedback</Box>
        <Box className="mb-6 text-gray-500">Why wasn&apos;t this helpful?</Box>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            What was unhelpful about this recommendation?
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="1"
            name="radio-buttons-group"
            className="px-1"
            onChange={(event) => setRadioValue(event.target.value)}
          >
            {options.map((text, index) => (
              <FormControlLabel
                key={index}
                value={text}
                control={<Radio size="small" />}
                label={text}
              />
            ))}
          </RadioGroup>
          <TextField
            disabled={radioValue !== otherValue}
            onChange={(value) => setOtherFeedback(value.target.value)}
            placeholder="Please specify"
            className="mb-6 ml-8 w-44"
          />
          <Box className="mb-2">
            (Optional) Feel free to add specific details
          </Box>
          <TextField
            onChange={(event) => setFeedbackDetails(event.target.value)}
            className="mb-6"
          />
          <Stack direction="row-reverse">
            <Button
              variant="contained"
              onClick={onSubmit}
              endIcon={<HiChevronRight />}
            >
              Submit
            </Button>
          </Stack>
        </FormControl>
      </Box>
    </Dialog>
  );
}
