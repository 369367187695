import { UserProfileResponse } from '@nirvana/api/auth';

import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';

export interface ISegmentTrackUserIdentify {
  userId: string;
  traits: any;
  options?: any;
}

export enum EventTrack {
  SearchDOT = 'SPDotSearched',
  StarDOT = 'SPDotStarred',
  StarredDOTDelete = 'SPStarredDotDeleted',
  StarredDOTView = 'SPStarredDotViewed',
  SidebarLinkSelect = 'SPSidebarLinkSelected',
  DetailsView = 'SPOverviewDetailsViewed',
  RecommendationView = 'SPRecommendationViewed',
  RecommendationDetailsExpand = 'SPRecommendationDetailsExpanded',
  RecommendationDetailsCollapse = 'SPRecommendationDetailsCollapsed',
  RecommendationsFilter = 'SPRecommendationsFilterApplied',
  FlagView = 'SPFlagViewed',
  FlagDetailsExpand = 'SPFlagDetailsExpanded',
  FlagDetailsCollapse = 'SPFlagDetailsCollapsed',
  FlagsFilter = 'SPFlagsFilterApplied',
  DateRangeFilter = 'SPDateRangeFilterApplied',
  TelematicsConnect = 'SPTelematicsConnectAttempted',
  OtherTelematicsConnect = 'SPOtherTelematicsConnectAttempted',
  DotPin = 'SPDotPinAttempted',
  ShareDOT = 'SPShareDotAttempted',
  Login = 'SPLoginAttempted',
  UnlockDOT = 'SPDotPinEntered',
  UnlockDOTFailed = 'SPDotPinEnteredFailed',
  UnlockDOTSuccessful = 'SPDotPinEnteredSucessfully',
  PerksLinkClicked = 'SPPerksLinkClicked',
  RecommendationFeedback = 'SPRecommendationFeedback',
  TrainingVideoPercentagePlayed = 'SPTrainingVideoPercentagePlayed',
  TrainingVideoPlayed = 'SPTrainingVideoPlayed',
  UserActivateSuccessful = 'SPUserActivateSuccessful',
  CompleteFMCSALogin = 'SPCompleteTwoFA',
  CSAViolationsTimelineClicked = 'SPCSAViolationsTimelineClicked',
  CSAViolationsTimelineHovered = 'SPCSAViolationsTimelineHovered',
  SafetyManagerBookingClicked = 'SPSafetyManagerBookingClicked',
  NotificationPreferencesUnsubscribe = 'SPNotificationPreferencesUnsubscribe',
  FNOLFormSubmissions = 'SPFNOLFormSubmissions',
  AboutSafetyScoreHelpful = 'SPAboutSafetyScoreHelpful',
  AboutSafetyScoreNotHelpful = 'SPAboutSafetyScoreNotHelpful',
  ShowMeHowAssignDriver = 'SPShowMeHowAssignDriver',
}

export interface ISegmentTrackEvent {
  event: EventTrack;
  properties?: any;
}

const deleteCookie = (name: string) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

const getUTMCookies = () => {
  return document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith('utm_'))
    .map((c) => c.split('='));
};

const removeUTMCookies = () => {
  const utmParams = getUTMCookies();
  utmParams.forEach((param) => {
    deleteCookie(param[0]);
  });
};

const getUTMParams = () => {
  const utmParams = getUTMCookies();

  const utmParamsAsObject = utmParams.reduce((acc, curr) => {
    acc[curr[0]] = curr[1];
    return acc;
  }, {} as Record<string, string>);

  const intialUtmParamsAsObject = utmParams.reduce((acc, curr) => {
    acc[`$initial_${curr[0]}`] = curr[1];
    return acc;
  }, {} as Record<string, string>);

  return {
    $set: {
      ...utmParamsAsObject,
    },
    $set_once: {
      ...intialUtmParamsAsObject,
    },
  };
};
export const useAnalytics = () => {
  const posthog = usePostHog();

  const trackUserIdentify = useCallback(
    (user: UserProfileResponse) => {
      const data: ISegmentTrackUserIdentify = {
        userId: user.id,
        traits: {
          agencyId: user.defaultAgencyId,
          name: user.name,
          email: user.email,
          userType: user.userType,
        },
      };
      posthog?.identify(user.id, data.traits);
    },
    [posthog],
  );

  const trackPageView = useCallback(
    (path: string) => {
      const utmParams = getUTMParams();

      if (Object.keys(utmParams).length > 0) {
        posthog.capture('$set', utmParams);
      }

      posthog?.capture('$pageview', {
        $current_url: window.location.origin + path,
        ...utmParams,
      });

      removeUTMCookies();
    },
    [posthog],
  );

  const trackPageLeave = useCallback(() => {
    posthog?.capture('$pageleave', {
      $pathname: window.location.href,
    });
  }, [posthog]);

  const resetIdentity = useCallback(() => {
    posthog?.reset();
    removeUTMCookies();
  }, [posthog]);

  const trackEvent = useCallback(
    (request: ISegmentTrackEvent) => {
      const properties =
        request.properties !== undefined ? request.properties : {};

      posthog?.capture(request.event, properties);
    },
    [posthog],
  );

  return {
    trackUserIdentify,
    resetIdentity,
    trackPageView,
    trackPageLeave,
    trackEvent,
  };
};
