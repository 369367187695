import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  HiChevronRight,
  HiOutlineInformationCircle,
  HiOutlineShieldCheck,
} from 'react-icons/hi';
import { CircularProgress } from '@material-ui/core';
import {
  getProviderInfoFromSafety,
  Show,
  Switch,
  Tooltip,
} from '@nirvana/ui-kit';

import { telematicsInsightsDisabled } from 'src/helpers';
import useLocalStorage from 'src/hooks/use-local-storage';
import UnlockTelematics from 'src/components/unlock-telematics';
import Cogs from 'src/assets/icons/cogs.svg?react';
import {
  TelematicsRiskScoreQuery,
  useTelematicsRiskScoreQuery,
} from 'src/types/graphql-types';
import { Feature, useFeatureValue } from 'src/helpers/feature-flags';

import subMonths from 'date-fns/subMonths';
import format from 'date-fns/format';
import { Banner } from 'src/components/Banner';
import RSPieChart from './rs-pie-chart';
import NssInfoDialog from './nss-info-dialog';

const DATE_FORMAT = 'MM/dd/yyyy';

const calculatePeriod = (data: TelematicsRiskScoreQuery | undefined) => {
  const lastMonthWithData =
    data?.fleetSafetyReport?.telematicsRiskFleetPercentiles.find(
      (scoreTrendItem) => scoreTrendItem.score !== undefined,
    );
  if (!lastMonthWithData) {
    return null;
  }
  const date = new Date(lastMonthWithData?.timestamp);
  const threeMonthsAgo = subMonths(date, 3);

  return `${format(threeMonthsAgo, DATE_FORMAT)} to ${format(
    date,
    DATE_FORMAT,
  )}`;
};

const RecommendationSkeleton = () => {
  return (
    <div className="flex-1 space-y-2">
      <div className="w-3/4 h-4 bg-gray-100 rounded animate-pulse" />
      <div className="w-1/4 h-6 bg-gray-100 rounded animate-pulse" />
      <div className="w-full h-48 bg-gray-100 rounded animate-pulse" />
      <div className="w-3/4 h-4 bg-gray-100 rounded animate-pulse" />
    </div>
  );
};

const ExcitingUpdates = () => {
  return (
    <>
      <div className="bg-[#DBE0FF] text-5xl text-primary-dark p-2 mb-4 rounded-lg">
        <HiOutlineInformationCircle />
      </div>
      <p className="mb-3 font-bold text-primary-main">
        Exciting updates are on the way!
      </p>
      <p className="text-center text-primary-main">
        Stay tuned for upcoming enhancements that will equip you with the
        information needed to improve your score.
      </p>
    </>
  );
};

export default function NirvanaSafetyScore() {
  const { reportId = '' } = useParams();
  const [isBannerVisible, setIsBannerVisible] = useLocalStorage(
    'NSS-banner-visibility',
    true,
  );
  const [nssDialogVisibility, setNssDialogVisibility] = useLocalStorage(
    'Nss-dialog-visibility',
    true,
  );

  const { data, loading, error } = useTelematicsRiskScoreQuery({
    variables: { reportId },
  });

  let currentMonthData =
    data?.fleetSafetyReport?.telematicsRiskFleetPercentiles[0];
  if (currentMonthData?.score !== undefined) {
    // Scores are returned where high is good, but we want to display 100-score, so that low scores are good
    currentMonthData = {
      score: 100 - currentMonthData.score,
      timestamp: currentMonthData.timestamp,
      isConfidentScore: currentMonthData.isConfidentScore,
      lowConfidenceReason: currentMonthData.lowConfidenceReason,
    };
  }
  const prevMonthScore =
    100 -
    (data?.fleetSafetyReport?.telematicsRiskFleetPercentiles[1]?.score ?? 100);

  const hasTelematicsConnection =
    !!data?.fleetSafetyReport?.hasTelematicsConnection;

  let isTelematicsInsightsDisabled = telematicsInsightsDisabled(
    data?.fleetSafetyReport?.TspProvider,
  );

  const dotsForDisabledTRS = (
    useFeatureValue(Feature.DISABLE_SPECIFIC_DOT_TRS) || ''
  ).split(',');
  if (data?.fleetSafetyReport?.dotNumber !== undefined) {
    isTelematicsInsightsDisabled =
      isTelematicsInsightsDisabled ||
      dotsForDisabledTRS.includes(data.fleetSafetyReport.dotNumber);
  }
  // Disable Fleet TRS score if the score is not confident.
  if (!currentMonthData?.isConfidentScore) {
    isTelematicsInsightsDisabled = true;
  }

  const scoreStatus = useMemo(() => {
    if (!hasTelematicsConnection) {
      return 'ConsentNotReceived';
    } else if (
      error ||
      !data?.fleetSafetyReport?.telematicsRiskFleetPercentiles.length
    ) {
      return 'ScoresNotAvailable';
    }
    return 'ScoresAvailable';
  }, [data, error, hasTelematicsConnection]);

  const period = calculatePeriod(data);

  return (
    <Show when={loading || !isTelematicsInsightsDisabled}>
      <div className="mb-6 bg-white rounded-lg shadow">
        <div className="flex flex-wrap items-center justify-between p-4 space-x-4 border-b">
          <div className="flex items-center">
            <div className="p-1 text-xl rounded text-primary-main bg-primary-extraLight">
              <HiOutlineShieldCheck />
            </div>

            <span className="ml-2 text-xl font-semibold text-gray-800">
              Nirvana Safety Score
            </span>

            <Tooltip
              enterTouchDelay={0}
              title="The Nirvana Safety Score, based on predictive models built using 3 billion miles of driving data, shows the likelihood of accidents compared to other fleets."
            >
              <span className="text-lg text-primary-main">
                <HiOutlineInformationCircle />
              </span>
            </Tooltip>
          </div>
          <div className="mt-2">
            <Show when={period}>
              <Tooltip
                enterTouchDelay={0}
                title={
                  <div className="max-w-xs">
                    This score is calculated using telematics data collected
                    over a rolling 3-month period prior to the score&apos;s
                    publication month. For example, the January safety score
                    incorporates data from October 1 to December 31 of the
                    preceding months. This method aims to reflect recent driving
                    behaviors and conditions.
                  </div>
                }
              >
                <div className="flex text-text-secondary">
                  <span className="mr-2 text-xs">Period: {period}</span>
                  <HiOutlineInformationCircle />
                </div>
              </Tooltip>
              <Show when={data?.fleetSafetyReport?.TspProvider}>
                <div className="flex justify-start text-xs text-text-secondary">
                  <p>Telematics Provider:</p>
                  <p className="ml-1">
                    {data?.fleetSafetyReport?.TspProvider &&
                      getProviderInfoFromSafety(
                        data?.fleetSafetyReport?.TspProvider,
                      ).name}
                  </p>
                </div>
              </Show>
            </Show>
          </div>
        </div>

        <Show when={isBannerVisible}>
          <div className="mx-4">
            <Banner
              title="Improvements to Safety Scores"
              cta={
                <>
                  <button
                    className="flex items-center"
                    onClick={() => setNssDialogVisibility(true)}
                  >
                    <span>Learn More</span>
                    <HiChevronRight className="ml-0.5" />
                  </button>
                  <button onClick={() => setIsBannerVisible(false)}>
                    Dismiss
                  </button>
                </>
              }
            >
              <p className="mb-2">
                We&apos;ve improved the accuracy of the Nirvana Safety Score,
                giving you a better indication of your fleet&apos;s accident
                risk.
              </p>
            </Banner>
          </div>
        </Show>

        <div className="relative grid gap-4 p-4 lg:grid-cols-2">
          <Switch>
            <Switch.Match when={loading}>
              <div className="flex items-center justify-center p-4 border rounded-md h-80">
                <CircularProgress />
              </div>

              <div className="flex flex-col p-4 border rounded-md">
                <RecommendationSkeleton />
              </div>
            </Switch.Match>

            <Switch.Match when={!loading}>
              <RSPieChart
                data={currentMonthData}
                prevMonthScore={prevMonthScore}
                hasTelematicsConnection={hasTelematicsConnection}
              />

              <div className="flex flex-col items-center justify-center p-4 border rounded-md">
                <div className="flex flex-col items-center justify-center md:w-72">
                  <Switch>
                    <Switch.Match when={hasTelematicsConnection}>
                      <ExcitingUpdates />
                    </Switch.Match>

                    <Switch.Match when={!hasTelematicsConnection}>
                      <div className="w-12 my-2 bg-gray-100 h-11" />
                      <div className="w-40 h-6 mb-2 bg-gray-100" />
                      <div className="w-48 bg-gray-100 h-14" />
                    </Switch.Match>
                  </Switch>
                </div>
              </div>

              <Show when={scoreStatus === 'ConsentNotReceived'}>
                <UnlockTelematics />
              </Show>

              <Show when={scoreStatus === 'ScoresNotAvailable'}>
                <div className="absolute inset-0 z-10 flex flex-col items-center justify-center h-full mx-auto space-y-4 backdrop-blur-md">
                  <div className="flex items-center justify-center p-4 rounded-lg bg-primary-extraLight">
                    <Cogs />
                  </div>
                  <p className="text-xl font-normal text-secondary-main">
                    Processing... We&apos;ll notify you
                  </p>
                  <p className="max-w-sm text-xs font-normal leading-5 text-center text-text-hint">
                    Meanwhile explore the platform to understand your
                    fleet&apos;s safety health
                  </p>
                </div>
              </Show>
            </Switch.Match>
          </Switch>
        </div>
      </div>

      <NssInfoDialog
        open={!loading && nssDialogVisibility}
        onClose={() => setNssDialogVisibility(false)}
      />
    </Show>
  );
}
