import { useState } from 'react';
import { Button } from '@material-ui/core';
import ConnectTspModal from 'src/components/connect-tsp-modal';
import TelematicsInsightsIcon from 'src/assets/icons/telematics-insights.svg?react';

export default function UnlockTelematics() {
  const [showTelematicsModal, setShowTelematicsModal] = useState(false);

  function handleShowTelematicsModal() {
    setShowTelematicsModal(true);
  }

  function handleCloseTelematicsModal() {
    setShowTelematicsModal(false);
  }

  return (
    <>
      <div className="absolute inset-0 flex items-center justify-center p-4">
        <div className="flex max-w-full px-4 py-6 border rounded w-96 border-primary-light bg-primary-extraLight">
          <TelematicsInsightsIcon className="w-56 mr-4" />
          <div>
            <p className="mb-2">Telematics insights</p>
            <p className="mb-4 text-xs text-text-hint">
              Connect to your ELD provider to generate your Nirvana safety
              score, unlock route level recommendations and more
            </p>
            <Button variant="contained" onClick={handleShowTelematicsModal}>
              Connect
            </Button>
          </div>
        </div>
      </div>
      <ConnectTspModal
        open={showTelematicsModal}
        onClose={handleCloseTelematicsModal}
      />
    </>
  );
}
