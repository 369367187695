import { Container, Typography } from '@material-ui/core';
import {
  Branding,
  BrandingTypes,
  HorizontalStepper,
  Show,
} from '@nirvana/ui-kit';
import BagIcon from '@nirvana/ui-kit/src/assets/icons/bag.svg?react';
import Carousel from 'src/components/carousel';
import { useSearchParams } from 'react-router-dom';
import First from './images/carousel/1.png';
import Second from './images/carousel/2.png';
import Third from './images/carousel/3.png';
import Fourth from './images/carousel/4.png';
import SignUp from './components/signUp/sign-up';

export const TelematicsSignUp = () => {
  const [searchParams] = useSearchParams();
  const fleetName = searchParams.get('fleetName');
  const dotNumber = searchParams.get('dotNumber');

  return (
    <div className="min-h-screen bg-white">
      <div className="pt-4 pb-8 bg-gradient-to-b from-gradient-primary to-gradient-secondary ">
        <Container>
          <header className="flex items-center justify-between mb-4">
            <Branding brandingType={BrandingTypes.Icon} />
            <section className="md:flex">
              <Show when={fleetName}>
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-4 h-4 mr-2 rounded-full bg-primary-tint3 text-md">
                    <BagIcon />
                  </div>
                  <Typography variant="caption" className="text-secondary-main">
                    {fleetName}
                  </Typography>
                </div>
              </Show>
              <Show when={dotNumber && fleetName}>
                <Typography
                  variant="caption"
                  className="hidden mx-2 md:block text-text-disabled"
                >
                  |
                </Typography>
              </Show>
              <Show when={dotNumber}>
                <Typography variant="caption" className="mr-1">
                  DOT:
                </Typography>
                <Typography variant="caption" className="text-secondary-main">
                  {dotNumber}
                </Typography>
              </Show>
            </section>
          </header>
          <HorizontalStepper
            steps={[
              { key: 0, label: '' },
              { key: 1, label: '' },
              { key: 2, label: '' },
              { key: 3, label: '' },
              { key: 4, label: '' },
            ]}
            activeStep={4}
            onChange={() => null}
          />
          <Typography variant="h2" className="mb-4 text-3xl text-center">
            Create an account to analyze your safety data
          </Typography>
        </Container>
      </div>
      <Container className="-mt-8">
        <section className="grid md:grid-cols-2 md:items-center">
          <section className="bg-white border rounded-lg shadow-[0_17px_18px_0_rgba(128,145,196,0.13)]">
            <SignUp />
          </section>
          <aside className="container min-w-full px-12 my-8 md:my-0 md:row-start-1">
            <Carousel sliderProps={{ slidesToShow: 1 }}>
              <div className="text-center">
                <img src={First} />
                <p className="px-12 pt-4">
                  See what Nirvana sees when reviewing your application with the
                  Safety Intelligence Platform.
                </p>
              </div>
              <div className="p-4 ">
                <img src={Second} />
                <p className="px-12 pt-4">
                  Identify which drivers contribute the most to accidents and
                  violations
                </p>
              </div>
              <div className="p-4 ">
                <img src={Third} />
                <p className="px-12 pt-4">
                  See the DOT hotspots that contribute to high BASIC scores.
                </p>
              </div>
              <div className="p-4 ">
                <img src={Fourth} />
                <p className="px-12 pt-4">
                  Monitor fleet safety each month and use our AI-powered
                  recommendations to improve fleet safety for the long haul.
                </p>
              </div>
            </Carousel>
          </aside>
        </section>
        <footer className="mt-8 text-center">
          By creating your account, you’re agreeing to our{' '}
          <a
            className="underline text-text-blue"
            href="https://www.nirvanatech.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            className="underline text-text-blue"
            href="https://www.nirvanatech.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </footer>
      </Container>
    </div>
  );
};
