import { Link } from 'react-router-dom';
import { HiStar, HiOutlineStar } from 'react-icons/hi';
import { Tooltip, IconButton } from '@material-ui/core';
import { Show, Switch } from '@nirvana/ui-kit';

import useAuth, { isAnonymousUser, isFleetUser } from 'src/hooks/useAuth';
import { analytics } from 'src/helpers';
import {
  SearchHistoryDocument,
  useFleetDetailsQuery,
  useFleetStarMutation,
} from 'src/types/graphql-types';
import ArrowBackIcon from 'src/assets/icons/arrow-back.svg?react';
import { useGetDOTFromParams } from 'src/hooks/useGetDOTFromParams';
import { useAnalytics } from 'src/helpers/analytics';

const getBackButtonProps = (
  path: string,
): { text: string; linkTo: string; onClick: any } => {
  switch (path) {
    case '/claims/new':
      return {
        text: 'All Claims',
        linkTo: 'claims',
        onClick: (e: Event) => {
          if (
            !confirm(
              'Are you sure you want to leave this page? Any unsaved changes will be lost.',
            )
          ) {
            e.preventDefault();
          }
        },
      };
    default:
      return { text: 'Back to search', linkTo: '/search', onClick: () => {} };
  }
};

/**
 * Header component is used inside AppShell to display the header.
 * It renders `Back` button, DoT Number and fleet name on the top of the page.
 */
export default function Header() {
  const { user } = useAuth();
  const { trackEvent } = useAnalytics();
  const { reportId, dotNumber } = useGetDOTFromParams({
    withReportId: true,
  });
  const pathname =
    location.pathname.split(`/${dotNumber}`)[1] ?? location.pathname;
  const {
    text: backButtonText,
    linkTo,
    onClick,
  } = getBackButtonProps(pathname);

  const isAgentUser =
    user?.userType === 'agent' || user?.userType === 'nirvana';

  const { data, loading, refetch } = useFleetDetailsQuery({
    variables: { reportId },
  });

  const isStarred = data?.fleetSafetyReport?.starred;

  const [fleetStarMutation] = useFleetStarMutation({
    variables: { reportId, markStar: !isStarred },
    refetchQueries: [{ query: SearchHistoryDocument }],
    onCompleted: () => refetch(),
  });

  const handleStarClick = () => {
    trackEvent({
      event: analytics.EventTrack.StarDOT,
      properties: {
        dotNumber: data?.fleetSafetyReport?.dotNumber,
        reportId,
      },
    });

    fleetStarMutation();
  };

  return (
    <header className="flex flex-wrap items-center justify-between px-6 py-2 shadow">
      <Show when={isAgentUser} fallback={<div />}>
        <Link
          to={linkTo}
          className="flex items-center mb-5 gap-x-2 group md:mb-0"
          onClick={onClick}
        >
          <span className="p-2 rounded-full group-hover:bg-gray-100">
            <ArrowBackIcon />
          </span>
          <span>{backButtonText}</span>
        </Link>
      </Show>

      <div className="flex items-center justify-center w-full space-x-4 md:w-auto">
        <Switch>
          <Switch.Match when={loading}>
            <div className="w-32 h-6 bg-gray-100 rounded animate-pulse" />
          </Switch.Match>
          <Switch.Match when={data}>
            <span className="text-sm font-medium text-secondary-main">
              {data?.fleetSafetyReport?.name}
            </span>
          </Switch.Match>
        </Switch>

        <div className="w-px h-4 bg-primary-light/25" />

        <Switch>
          <Switch.Match when={loading}>
            <div className="w-24 h-6 bg-gray-100 rounded animate-pulse" />
          </Switch.Match>
          <Switch.Match when={data}>
            <span className="inline-block text-sm font-medium text-secondary-main">
              {data?.fleetSafetyReport?.dotNumber}
            </span>
          </Switch.Match>
        </Switch>

        <Show
          when={!isFleetUser(user) && !isAnonymousUser(user)}
          fallback={<div />}
        >
          <Tooltip title={`Add${isStarred ? 'ed' : ''} to Starred`} arrow>
            <IconButton size="small" onClick={handleStarClick}>
              <Show when={isStarred} fallback={<HiOutlineStar />}>
                <HiStar className="w-5 h-5 text-gold-main" />
              </Show>
            </IconButton>
          </Tooltip>
        </Show>
      </div>

      <IconButton size="small" />
    </header>
  );
}
