import { MenuItem, Select } from '@material-ui/core';
import CalendarIcon from 'src/assets/icons/calender.svg?react';

type SelectPeriodProps = {
  value: number;
  onChange: (period: number) => void;
};

export default function SelectPeriod({ value, onChange }: SelectPeriodProps) {
  return (
    <Select
      displayEmpty
      variant="outlined"
      className="w-full md:w-auto"
      value={value}
      onChange={(event) => onChange(+event.target.value)}
      startAdornment={<CalendarIcon width="26" height="26" />}
    >
      <MenuItem value={3}>Last 3 months</MenuItem>
      <MenuItem value={6}>Last 6 months</MenuItem>
      <MenuItem value={12}>Last 1 year</MenuItem>
      <MenuItem value={24}>Last 2 years</MenuItem>
    </Select>
  );
}
