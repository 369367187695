import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { LineChart, TableTabs } from '@nirvana/ui-kit';
import ChartTrend from 'src/assets/icons/chart-trend.svg?react';
import { useTrendsQuery } from 'src/types/graphql-types';
import { formatNumber } from 'src/helpers';
import { getLineChartProps, TabKeys, tabs } from '../constants/trends';

export default function Trends() {
  const { reportId = '' } = useParams();
  const [selectedTab, setSelectedTab] = useState<TabKeys>('pU');

  const { data: trends } = useTrendsQuery({
    variables: { reportId },
  });

  const chartData = useMemo(
    () =>
      trends?.fleetSafetyReport?.GeneralTrends.map((record) => ({
        [selectedTab]: record[selectedTab as keyof typeof record],
        date: format(new Date(record.year, record.month), 'MMM, yy'),
      }))
        .slice(0, 20)
        .reverse() ?? [],
    [trends?.fleetSafetyReport?.GeneralTrends, selectedTab],
  );

  const currentScore = {
    pU: 'Current Power Units',
    mileage: 'Recent Mileage',
    utilization: 'Current Utilization',
    score: chartData[chartData.length - 1]?.[selectedTab] as number,
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow lg:col-span-7">
      <div className="flex items-center mb-4 space-x-3">
        <ChartTrend />
        <span className="font-semibold text-secondary-main">Trends</span>
      </div>

      <div>
        <TableTabs
          value={selectedTab}
          onChange={(value: string) => {
            setSelectedTab(value as TabKeys);
          }}
          displayCount={false}
          tabs={tabs}
        />
      </div>

      <div className="flex items-center justify-end my-4">
        <p className="mr-2 text-text-primary">{currentScore[selectedTab]}:</p>
        <span className="text-base font-medium text-success-main">
          {formatNumber(currentScore.score)}
        </span>
      </div>

      <div className="h-64">
        <LineChart data={chartData} {...getLineChartProps(selectedTab)} />
      </div>
    </div>
  );
}
