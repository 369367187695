import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import { getWeightForViolation } from 'src/helpers/getWeightForViolation';
import { DriversQuery } from 'src/types/graphql-types';
import { mostPopularDateForVinPercentiles } from './dateHelpers';

export type AggregatedInspection = {
  name: string;
  inspections: {
    total: number;
    clean: number;
    withViolations: number;
  };
  weight: number;
  VINs: string[];
  score: number | undefined;
  totalMilesDriven?: number;
};

type MappedScoresPerDriver = Record<
  string,
  {
    name: string;
    score: number | undefined;
    vins: string[];
  }
>;

const mapVinToMileage = (report: DriversQuery['fleetSafetyReport']) => {
  if (!report?.telematicsVehicles) {
    return {};
  }
  const vinToMileage = report.telematicsVehicles.reduce((acc, vinMileage) => {
    if (!vinMileage.vin || !vinMileage.mileage) {
      return acc;
    }
    acc[vinMileage.vin] = Math.round(vinMileage.mileage.distanceMiles);
    return acc;
  }, {} as Record<string, number>);
  return vinToMileage;
};

const mapScoresToDrivers: (
  report: DriversQuery['fleetSafetyReport'],
) => MappedScoresPerDriver = (report) => {
  if (!report?.telematicsRiskVinPercentiles) {
    return {} as MappedScoresPerDriver;
  }

  type AggregatedRiskVin = {
    vin: string;
    score: number | undefined;
    driver: string;
    driverName: string;
    assignedDurationMs: number;
  };

  const mostPopularDate = mostPopularDateForVinPercentiles(
    report.telematicsRiskVinPercentiles,
  );

  const aggregatedRiskVinPerVIN = report.telematicsRiskVinPercentiles
    .filter((RiskVin) => {
      const hasScores = RiskVin.scores.length > 0;
      if (!hasScores) {
        return hasScores;
      }

      const hasDriver = RiskVin.scores[0]?.telematicsAssignments?.[0]?.driver;
      return hasDriver;
    })
    .reduce((acc, RiskVin) => {
      const { vin, scores } = RiskVin;

      const isTheScoreForPopularDate =
        RiskVin.scores[0].timestamp === mostPopularDate;
      const isDateOlderThanExpected =
        differenceInDays(new Date(), parseISO(RiskVin.scores[0].timestamp)) >
        60;

      const totalAssignedDuration =
        RiskVin.scores[0].telematicsAssignments.reduce((acc, assignment) => {
          acc += assignment.assignedDurationMs;
          return acc;
        }, 0);

      const primaryDriverMeetsThreshold =
        RiskVin.scores[0]?.telematicsAssignments[0]?.assignedDurationMs >
        totalAssignedDuration * 0.7;

      const isValidScore =
        isTheScoreForPopularDate &&
        !isDateOlderThanExpected &&
        primaryDriverMeetsThreshold;

      acc.push({
        vin,
        score: isValidScore ? scores[0].score : undefined,
        driver: scores[0].telematicsAssignments[0].driver!.id,
        driverName: scores[0].telematicsAssignments[0].driver!.name,
        assignedDurationMs:
          scores[0].telematicsAssignments[0].assignedDurationMs,
      });
      return acc;
    }, [] as Array<AggregatedRiskVin>);

  const aggregatedRiskVinPerDriver = aggregatedRiskVinPerVIN.reduce(
    (acc, riskVin) => {
      const { driver, driverName } = riskVin;

      if (acc[driver]) {
        return acc;
      }

      const allRiskVinsForDriver = aggregatedRiskVinPerVIN.filter(
        (riskVin) => riskVin.driver === driver,
      );

      const totalAssignedDuration =
        allRiskVinsForDriver.reduce(
          (acc, riskVin) => acc + riskVin.assignedDurationMs,
          0,
        ) ?? 1;

      const totalRiskScore = allRiskVinsForDriver
        .filter((riskVin) => riskVin.score !== undefined)
        .reduce((acc, riskVin) => {
          if (acc === undefined) {
            acc = 0;
          }
          return (
            acc +
            ((riskVin.score as number) * riskVin.assignedDurationMs) /
              totalAssignedDuration
          );
          // we define the type as undefined to be able to differentiate
          // between users with a perfect score and users with no score
        }, undefined as number | undefined);

      acc[driver] = {
        name: driverName,
        score:
          totalRiskScore !== undefined
            ? Math.round(100 - totalRiskScore)
            : undefined,
        vins: allRiskVinsForDriver.map((riskVin) => riskVin.vin),
      };
      return acc;
    },
    {} as MappedScoresPerDriver,
  );

  return aggregatedRiskVinPerDriver;
};

type LegacyInspections = NonNullable<
  DriversQuery['fleetSafetyReport']
>['inspections'][0];

type DatagovInspection = NonNullable<
  DriversQuery['fleetSafetyReport']
>['datagovInspections'][0];

type LegacyInspectionsById = Record<string, LegacyInspections>;

export const getLegacyInspectionsById: (
  report: DriversQuery['fleetSafetyReport'],
) => LegacyInspectionsById = (report) => {
  if (!report?.inspections) {
    return {};
  }

  return report.inspections.reduce((acc, inspection) => {
    acc[inspection.inspectionID] = inspection;
    return acc;
  }, {} as LegacyInspectionsById);
};

export const computeInspectionViolationsWeight = (
  LegacyInspectionsById: LegacyInspectionsById,
  inspection: DatagovInspection,
) => {
  return LegacyInspectionsById[inspection.id].violations.reduce(
    (acc, violation) => {
      const weight = violation.totalSeverityWeight
        ? getWeightForViolation({
            severityWeight: violation.totalSeverityWeight,
            inspectionDate: inspection.inspectionDate,
            oosIndicator: false, // as we use totalSeverityWeight, we don't need to check for oos
          })
        : 0;

      return acc + weight;
    },
    0,
  );
};

export const mapInspectionsToDrivers: (
  report: DriversQuery['fleetSafetyReport'],
) => AggregatedInspection[] = (report) => {
  if (!report?.datagovInspections) {
    return [];
  }

  const mappedScoresPerDriver = mapScoresToDrivers(report);

  const legacyInspectionsIds = report.inspections.map(
    (inspection) => inspection.inspectionID,
  );

  const drivenMileagesPerVIN = mapVinToMileage(report);
  const legacyInspectionsById = getLegacyInspectionsById(report);

  const aggregatedInspections = report.datagovInspections
    .filter((inspection) => {
      const hasTelematicsAssignment =
        inspection.telematicsAssignments.length > 0;

      if (!hasTelematicsAssignment) {
        return hasTelematicsAssignment;
      }

      const hasDriver = inspection.telematicsAssignments[0]?.driver;
      if (!hasDriver) {
        return hasDriver;
      }
      // Skip inspections that are not in the legacy violations as they might not be published
      if (!legacyInspectionsIds.includes(inspection.id)) {
        return false;
      }

      const totalAssignedDuration = inspection.telematicsAssignments.reduce(
        (acc, assignment) => {
          acc += assignment.assignedDurationMs;
          return acc;
        },
        0,
      );

      const primaryDriverMeetsThreshold =
        inspection.telematicsAssignments[0].assignedDurationMs >
        totalAssignedDuration * 0.7;

      return primaryDriverMeetsThreshold;
    })
    .reduce((acc, inspection) => {
      const { driver } = inspection.telematicsAssignments[0];
      const vin = inspection.telematicsAssignments[0].vehicle?.vin ?? 'N/A';

      const isCleanInspection = inspection.violations.length === 0;

      acc[driver!.id] = {
        name: driver!.name.split(',').reverse().join(' ').toLocaleLowerCase(),
        inspections: {
          total: (acc[driver!.id]?.inspections.total ?? 0) + 1,
          clean:
            (acc[driver!.id]?.inspections.clean ?? 0) +
            (isCleanInspection ? 1 : 0),
          withViolations:
            (acc[driver!.id]?.inspections.withViolations ?? 0) +
            (isCleanInspection ? 0 : 1),
        },
        weight:
          (acc[driver!.id]?.weight ?? 0) +
          computeInspectionViolationsWeight(legacyInspectionsById, inspection),
        VINs: Array.from(new Set((acc[driver!.id]?.VINs ?? []).concat(vin))),
        score: mappedScoresPerDriver[driver!.id]?.score ?? undefined,
      };
      return acc;
    }, {} as Record<string, AggregatedInspection>);

  // Add missing Drivers (that have no inspections)
  const missingDrivers = Object.keys(mappedScoresPerDriver).filter(
    (driver) => !Object.keys(aggregatedInspections).includes(driver),
  );

  missingDrivers.forEach((driver) => {
    aggregatedInspections[driver] = {
      name: mappedScoresPerDriver[driver]?.name.toLocaleLowerCase(),
      inspections: {
        total: 0,
        clean: 0,
        withViolations: 0,
      },
      weight: 0,
      VINs: mappedScoresPerDriver[driver]?.vins ?? [],
      score: mappedScoresPerDriver[driver].score,
    };
  });

  const aggregatedInspectionsWithTotalMilesDriven = Object.keys(
    aggregatedInspections,
  ).map((driver) => {
    const { VINs } = aggregatedInspections[driver];

    const totalMilesDriven = VINs.reduce((acc, vin) => {
      const vinMileage = drivenMileagesPerVIN[vin];
      if (vinMileage) {
        return acc + vinMileage;
      }
      return acc;
    }, 0);
    return {
      ...aggregatedInspections[driver],
      totalMilesDriven,
    };
  });

  return Object.values(aggregatedInspectionsWithTotalMilesDriven);
};
