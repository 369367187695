import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { CircularProgress } from '@material-ui/core';
import { startOfMonth, subMonths } from 'date-fns';
import {
  LineChart,
  Show,
  Switch,
  Tooltip,
  getFormattedDateInTimezone,
} from '@nirvana/ui-kit';

import {
  analytics,
  computeScoreTextColor,
  Dates,
  telematicsInsightsDisabled,
} from 'src/helpers';
import Cogs from 'src/assets/icons/cogs.svg?react';
import SelectPeriod from 'src/components/select-period';
import { useTelematicsRiskScoreQuery } from 'src/types/graphql-types';
import TelematicsOverlay from 'src/components/telematics-overlay';

import { Feature, useFeatureValue } from 'src/helpers/feature-flags';
import clsx from 'clsx';
import { useAnalytics } from 'src/helpers/analytics';
import { getISSLineChartProps } from '../constants/line-chart-props';
import { riskReferenceLines } from '../constants/iss-reference-lines';
import { ChartHeader } from './ChartHeader';

export default function SafetyScore() {
  const { Match } = Switch;
  const { reportId = '' } = useParams();
  const { trackEvent } = useAnalytics();
  const [period, setPeriod] = useState(12);

  const fromTimestamp = useMemo(() => {
    const startMonth = startOfMonth(subMonths(new Date(), period + 1));
    return Dates.formatISOUTC(startMonth);
  }, [period]);

  const { data, loading, error } = useTelematicsRiskScoreQuery({
    variables: { reportId, fromTimestamp },
  });

  useEffect(() => {
    trackEvent({
      event: analytics.EventTrack.DateRangeFilter,
      properties: {
        module: 'scores',
        chart: 'Safety Score',
        period: `Last ${period} months`,
      },
    });
  }, [period, trackEvent]);

  // Final Data for Nirvana Safety Score chart
  const safetyScore = useMemo(() => {
    if (data) {
      return (
        data?.fleetSafetyReport?.telematicsRiskFleetPercentiles
          .slice(0, period)
          .map(
            ({ score, timestamp, isConfidentScore, lowConfidenceReason }) => ({
              value: 100 - score,
              isConfidentScore,
              lowConfidenceReason,
              // Note: JavaScript counts months from 0 to 11:
              // name: format(new Date(timestamp), 'MMM, yyyy'),
              name: getFormattedDateInTimezone(timestamp, 'MMM, yyyy'),
            }),
          )
          .reverse() ?? []
      );
    }
    return [];
  }, [data, period]);

  const scoreStatus = useMemo(() => {
    if (!data?.fleetSafetyReport?.hasTelematicsConnection) {
      return 'ConsentNotReceived';
    } else if (error || !safetyScore.length) {
      return 'ScoresNotAvailable';
    }
    return 'ScoresAvailable';
  }, [data, error, safetyScore]);

  const latestTRSRecord = safetyScore?.slice(-1)[0];
  const currentScore = latestTRSRecord?.value;

  let isTelematicsInsightsDisabled = telematicsInsightsDisabled(
    data?.fleetSafetyReport?.TspProvider,
  );

  const dotsForDisabledTRS = (
    useFeatureValue(Feature.DISABLE_SPECIFIC_DOT_TRS) || ''
  ).split(',');
  if (data?.fleetSafetyReport?.dotNumber !== undefined) {
    isTelematicsInsightsDisabled =
      isTelematicsInsightsDisabled ||
      dotsForDisabledTRS.includes(data.fleetSafetyReport.dotNumber);
  }

  if (!latestTRSRecord?.isConfidentScore) {
    isTelematicsInsightsDisabled = true;
  }

  return (
    <Show when={!isTelematicsInsightsDisabled}>
      <section
        id="risk-score"
        className="relative overflow-hidden bg-white border rounded-lg"
      >
        <Switch>
          <Match when={loading}>
            <div className="absolute inset-0 z-10 flex items-center justify-center h-full bg-white">
              <CircularProgress />
            </div>
          </Match>

          <Match when={scoreStatus === 'ConsentNotReceived'}>
            <TelematicsOverlay />
          </Match>

          <Match when={scoreStatus === 'ScoresNotAvailable'}>
            <div className="absolute inset-0 z-10 flex flex-col items-center justify-center h-full mx-auto space-y-4 backdrop-blur-md">
              <div className="flex items-center justify-center p-4 rounded-lg bg-primary-extraLight">
                <Cogs />
              </div>
              <p className="text-xl font-normal text-secondary-main">
                Processing... We&apos;ll notify you
              </p>
              <p className="max-w-sm text-xs font-normal leading-5 text-center text-text-hint">
                Meanwhile explore the platform to understand your fleet&apos;s
                safety health
              </p>
            </div>
          </Match>
        </Switch>
        <ChartHeader
          title={
            <>
              <p className="mr-2 font-bold">Nirvana Safety Score</p>
              <Tooltip
                placement="right"
                title={
                  <div className="max-w-md">
                    The Nirvana Safety Score is based on predictive models using
                    more than 3 billion miles of driving data to determine the
                    likelihood of accidents compared to other fleets.
                  </div>
                }
              >
                <button className="text-primary-main">
                  <HiOutlineInformationCircle />
                </button>
              </Tooltip>
            </>
          }
          score={
            <>
              <p className="text-xs font-normal text-text-primary lg:inline-block">
                Current Score:
              </p>
              <span
                className={clsx(
                  'ml-2 text-base font-bold',
                  computeScoreTextColor(currentScore || 55),
                )}
              >
                {currentScore ?? 'Inconclusive'}
              </span>
              <span className="mx-1 mr-2 text-xs font-bold text-text-secondary">
                / 100
              </span>
            </>
          }
          periodPicker={<SelectPeriod value={period} onChange={setPeriod} />}
        />
        <div className="p-6 h-80">
          <LineChart
            data={safetyScore}
            referenceLines={riskReferenceLines}
            {...getISSLineChartProps({ period })}
            yField={['value']}
          />
        </div>
      </section>
    </Show>
  );
}
