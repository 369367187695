import { useEffect, useMemo, useState } from 'react';
import {
  endOfMonth,
  format,
  parseISO,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { LineChart, TableTabs, Tooltip } from '@nirvana/ui-kit';

import { useParams } from 'react-router-dom';
import { analytics, Dates } from 'src/helpers';
import SelectPeriod from 'src/components/select-period';
import { BasicScoreDetail, useFleetRatingQuery } from 'src/types/graphql-types';

import { useAnalytics } from 'src/helpers/analytics';
import { getBasicScoreLineChartProps } from '../constants/line-chart-props';
import { basicChartColors } from '../constants/rating';
import { basicTabs } from '../constants/basic-tabs';
import { ChartHeader } from './ChartHeader';

const CurrentScore = ({
  currentScore,
  threshold,
}: {
  currentScore: number | undefined | null;
  threshold: number;
}) => {
  if (!currentScore) {
    return <span className="ml-2 text-base text-gray">N/A</span>;
  }

  if (currentScore > threshold) {
    return (
      <span className="ml-2 text-base font-bold text-error-dark">
        {currentScore}
      </span>
    );
  }

  return (
    <span className="ml-2 text-base font-bold text-success-dark">
      {currentScore}
    </span>
  );
};

function BasicScoreChartTooltip(props: any) {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    return (
      <div className="w-56 px-4 py-3 space-y-1 rounded-md bg-text-primary">
        <p className="font-semibold text-primary-tint1">
          {payload[0].payload?.month}
        </p>
        <div className="flex items-center">
          <span className="text-xs text-primary-tint2">Score</span>
          <div className="flex-1" />
          <span className="font-semibold text-white">{payload[0].value}</span>
        </div>
        <div className="flex items-center">
          <span className="text-xs text-primary-tint2">Threshold</span>
          <div className="flex-1" />
          <span className="font-semibold text-white">
            {payload[0].payload?.threshold}
          </span>
        </div>
        <div className="flex items-center">
          <span className="text-xs text-primary-tint2">Measure</span>
          <div className="flex-1" />
          <span className="font-semibold text-white">
            {payload?.[0]?.payload?.measure}
          </span>
        </div>
      </div>
    );
  }

  return null;
}

const BasicScore = () => {
  const [period, setPeriod] = useState(12);
  const { trackEvent } = useAnalytics();
  const [selectedTab, setSelectedTab] = useState('Unsafe Driving');
  const { reportId = '' } = useParams();

  useEffect(() => {
    trackEvent({
      event: analytics.EventTrack.DateRangeFilter,
      properties: {
        module: 'scores',
        chart: 'Basic Score',
        period: `Last ${period} months`,
      },
    });
  }, [period, trackEvent]);

  const fromTimestamp = useMemo(() => {
    // We fetch data for an extra month because
    // last month's data is not available sometimes
    const startMonth = startOfMonth(subMonths(new Date(), period + 1));
    return Dates.formatISOUTC(startMonth);
  }, [period]);

  const toTimestamp = useMemo(() => {
    const lastMonth = endOfMonth(subMonths(new Date(), 1));
    return Dates.formatISOUTC(lastMonth);
  }, []);

  const { data } = useFleetRatingQuery({
    variables: { reportId, fromTimestamp, toTimestamp },
  });

  // Final Data for Basic score chart
  const basicScore = useMemo(() => {
    if (data) {
      const scores =
        data.fleetSafetyReport?.basicScores
          .slice(0, period)
          .reverse()
          .map((record) => {
            const score = record.scores.find((t) => t.name === selectedTab);
            const scoreDate = parseISO(record.scoreDate);

            if (score) {
              return {
                ...score,
                percentile: score.percentile ?? 0,
                measure:
                  score.measure && !isNaN(score.measure)
                    ? score.measure.toFixed(3)
                    : 0,
                month: format(scoreDate, 'MMM, yyyy'),
                color:
                  score.percentile && score.percentile < score.threshold
                    ? basicChartColors.success
                    : basicChartColors.error,
              };
            }

            return {} as BasicScoreDetail;
          }) ?? [];

      return scores.flat(Infinity);
    }
    return [];
  }, [data, selectedTab, period]);

  const threshold = basicScore[0]?.threshold ?? 0;

  const currentScore = basicScore?.[basicScore.length - 1]?.percentile;

  const scoreDate = useMemo(() => {
    const date = data?.fleetSafetyReport?.basicScores[0]?.scoreDate;
    return date ? format(parseISO(date), 'MM/dd/yyyy') : '';
  }, [data]);

  const tooltipProps = {
    content: <BasicScoreChartTooltip />,
  };

  return (
    <section id="basic-score" className="col-span-2 bg-white border rounded-lg">
      <ChartHeader
        title={
          <div>
            <div className="flex">
              <p className="mr-2 font-bold">BASIC Scores</p>
              <Tooltip
                title={
                  <div className="max-w-md">
                    Pursuant to the FAST Act of 2005, property carrier BASIC
                    scores have been removed from public view. These scores are
                    estimates calculated by Nirvana.
                  </div>
                }
                placement="right"
              >
                <button className="text-primary-main">
                  <HiOutlineInformationCircle />
                </button>
              </Tooltip>
            </div>
            <p className="block my-2 text-xs font-normal">
              Most Recent FMCSA Data Release Date: {scoreDate}
            </p>
          </div>
        }
        score={<></>}
        periodPicker={<SelectPeriod value={period} onChange={setPeriod} />}
      />
      <div className="p-6">
        <TableTabs
          value={selectedTab}
          onChange={(value: string) => {
            setSelectedTab(value);
          }}
          displayCount={false}
          tabs={basicTabs}
        />
        <div className="flex items-center justify-end my-4">
          <p className="text-xs font-normal text-text-primary">
            Current Score:
          </p>
          <CurrentScore threshold={threshold} currentScore={currentScore} />
        </div>
        <div className="h-80 ">
          <LineChart
            data={basicScore}
            {...getBasicScoreLineChartProps({ period, threshold })}
            tooltipProps={tooltipProps}
          />
        </div>
      </div>
    </section>
  );
};

export default BasicScore;
