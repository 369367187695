import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { TableTabs } from '@nirvana/ui-kit';

import { analytics } from 'src/helpers';
import { BlankStateRegular as BlankState } from 'src/components/blankState';
import {
  FlagSeverity,
  useFlagsQuery,
  useGetFlagsCountBySeverityQuery,
} from 'src/types/graphql-types';
import BlankIcon from 'src/assets/icons/triangle-check.svg?react';

import { useAnalytics } from 'src/helpers/analytics';
import FlagAccordion from './components/flag-accordion';
import { tabsList } from './constants/tabs-list';

export default function Flags() {
  const { reportId = '' } = useParams();
  const { trackEvent } = useAnalytics();
  const { hash } = useLocation();
  const [selectedSeverity, setSelectedSeverity] = useState('');

  useEffect(() => {
    trackEvent({
      event: analytics.EventTrack.FlagsFilter,
      properties: {
        severity: selectedSeverity,
      },
    });
  }, [selectedSeverity, trackEvent]);

  const { data, loading: isFetchingFlags } = useFlagsQuery({
    variables: { reportId },
  });

  const { data: severityCount } = useGetFlagsCountBySeverityQuery({
    variables: { reportId },
  });

  const flagsCountBySeverity = severityCount?.fleetSafetyReport;

  const flagDetails = useMemo(() => {
    if (data) {
      return data.fleetSafetyReport?.flags.edges.map((edge) => edge.node) ?? [];
    }
    return [];
  }, [data]);

  const flagsData = useMemo(() => {
    if (selectedSeverity) {
      return flagDetails.filter((data) => data.severity === selectedSeverity);
    }
    return flagDetails;
  }, [flagDetails, selectedSeverity]);

  const content = useMemo(() => {
    if (isFetchingFlags || !data) {
      return (
        <div className="flex items-center justify-center flex-grow">
          <CircularProgress />
        </div>
      );
    }

    if (!flagsData.length && !selectedSeverity) {
      return (
        <BlankState
          icon={<BlankIcon />}
          text="We didn’t find any flags"
          subText="We looked, but didn’t find any flags for the carrier. Nice job!
            We’re constantly monitoring the carrier’s data, so check back soon."
        />
      );
    }

    return (
      <>
        <div className="flex items-center justify-between">
          <div className="inline-block">
            <TableTabs
              tabs={tabsList.map((record) => ({
                ...record,
                count:
                  flagsCountBySeverity?.[record.id as FlagSeverity]
                    ?.totalCount || 0,
              }))}
              onChange={setSelectedSeverity}
              value={selectedSeverity}
            />
          </div>
        </div>

        <div className="py-6">
          <div className="grid grid-cols-12 px-3 mb-4 mr-8 text-xs font-normal tracking-wider text-secondary-dark">
            <div className="col-span-9">Flag details</div>
            <div className="col-span-2">Category</div>
            <div className="col-span-1">Severity</div>
          </div>
          {flagsData.map((data: any, index: number) => (
            <FlagAccordion
              key={data.id}
              data={data}
              expanded={
                hash === '' ? index === 0 : hash.replace('#', '') === data.id
              }
            />
          ))}
        </div>
      </>
    );
  }, [
    data,
    flagsCountBySeverity,
    flagsData,
    hash,
    isFetchingFlags,
    selectedSeverity,
  ]);

  return (
    <>
      <div className="flex items-center mb-4">
        <p className="text-3xl font-bold text-gray-800">Flags</p>
      </div>

      {content}
    </>
  );
}
