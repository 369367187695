import BookImage from 'src/assets/images/book.svg';
import { TrainingCard } from './components/TrainingCard';
import videos from './videos.json';

export const Training = () => {
  return (
    <div>
      <header className="flex items-center justify-between px-5 py-10">
        <section className="max-w-xs">
          <h1 className="text-3xl font-bold text-secondary-main">Trainings</h1>
          <h3 className="mt-4 text-secondary-dark">
            Share these curated videos with your drivers to equip them with
            essential knowledge and practices that will enhance their road
            safety and reduce risk for accidents.
          </h3>
        </section>
        <section className="hidden sm:block">
          <img src={BookImage} alt="" aria-hidden="true" />
        </section>
      </header>
      <section className="flex flex-wrap">
        {videos.map((video) => (
          <TrainingCard {...video} key={video.title} />
        ))}
      </section>
    </div>
  );
};
