import { useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { TableTabs } from '@nirvana/ui-kit';

import { analytics } from 'src/helpers';
import { useRecommendationsQuery } from 'src/types/graphql-types';
import BlankIcon from 'src/assets/icons/light.svg?react';
import { BlankStateRegular as BlankState } from 'src/components/blankState';

import { useAnalytics } from 'src/helpers/analytics';
import RecommendationAccordion from './components/recommendation-accordion';
import { tabsList } from './constants/tabs-list';

export default function Recommendations() {
  const { reportId = '' } = useParams();
  const { hash } = useLocation();
  const [selectedImpact, setSelectedImpact] = useState('');
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent({
      event: analytics.EventTrack.RecommendationsFilter,
      properties: {
        impact: selectedImpact,
      },
    });
  }, [selectedImpact, trackEvent]);

  const { data, loading } = useRecommendationsQuery({
    variables: { reportId },
  });

  const recommendations = useMemo(() => {
    if (data) {
      return (
        data.fleetSafetyReport?.recommendations.edges.map(
          (edge) => edge.node,
        ) ?? []
      );
    }
    return [];
  }, [data]);

  const recommendationData = useMemo(() => {
    if (selectedImpact) {
      return recommendations.filter((data) => data.impact === selectedImpact);
    }
    return recommendations;
  }, [selectedImpact, recommendations]);

  const recommendationCountByImpact = useMemo(() => {
    const count: any = {
      all: recommendations.length,
    };

    count.high = recommendations.filter(
      (data) => data.impact === 'High',
    ).length;

    count.medium = recommendations.filter(
      (data) => data.impact === 'Medium',
    ).length;

    count.low = recommendations.filter((data) => data.impact === 'Low').length;

    return count;
  }, [recommendations]);

  const content = useMemo(() => {
    if (loading || !data) {
      return (
        <div className="flex items-center justify-center flex-grow">
          <CircularProgress />
        </div>
      );
    }

    if (recommendationData.length || selectedImpact) {
      return (
        <>
          <TableTabs
            tabs={tabsList.map((record) => ({
              ...record,
              count: recommendationCountByImpact?.[record.id] || 0,
            }))}
            onChange={setSelectedImpact}
            value={selectedImpact}
          />

          <div className="py-6">
            <div className="grid grid-cols-12 px-3 mb-4 mr-8 text-xs font-normal tracking-wider text-secondary-dark">
              <div className="col-span-9">Recommendations</div>
              <div className="col-span-3">Impact</div>
            </div>
            {recommendationData.map((data, index) => (
              <RecommendationAccordion
                key={data.id}
                data={data}
                expanded={
                  hash === '' ? index === 0 : hash.replace('#', '') === data.id
                }
              />
            ))}
          </div>
        </>
      );
    }

    return (
      <BlankState
        icon={<BlankIcon />}
        text="We’re working on new recommendations for you"
        subText="We’re constantly monitoring your data to identify new recommendations. Check back soon!"
      />
    );
  }, [
    data,
    hash,
    loading,
    recommendationCountByImpact,
    recommendationData,
    selectedImpact,
  ]);

  return (
    <>
      <div className="flex items-center mb-4">
        <p className="text-3xl font-bold text-gray-800">Recommendations</p>
      </div>

      {content}
    </>
  );
}
