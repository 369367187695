import { DotRating } from 'src/types/graphql-types';
import SatisfactoryIcon from 'src/assets/icons/triangle-satisfactory.svg?react';
import ConditionalIcon from 'src/assets/icons/triangle-conditional.svg?react';
import UnsatisfactoryIcon from 'src/assets/icons/triangle-unsatisfactory.svg?react';
import NotRatedIcon from 'src/assets/icons/triangle-notrated.svg?react';

export const dotRatingStatus = {
  [DotRating.SatisfactoryRating]: {
    icon: SatisfactoryIcon,
    status: 'Satisfactory',
  },
  [DotRating.ConditionalRating]: {
    icon: ConditionalIcon,
    status: 'Conditional',
  },
  [DotRating.UnsatisfactoryRating]: {
    icon: UnsatisfactoryIcon,
    status: 'Unsatisfactory',
  },
  [DotRating.Unrated]: {
    icon: NotRatedIcon,
    status: 'Not-rated',
  },
};
