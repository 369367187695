import { Branding, BrandingTypes } from '@nirvana/ui-kit';
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';

const errorMessages: {
  [key: string]: {
    title: string;
    description: string;
    cta: {
      label: string;
      href: string;
    };
  };
} = {
  404: {
    title: 'Looks like you took a wrong turn',
    description: "This page doesn't exist. Let's get you back en route.",
    cta: {
      label: 'Return to Home',
      href: '/',
    },
  },
  default: {
    title: 'Oops! Something went wrong',
    description:
      'Something went wrong when trying to load this page. Please try again later or contact us.',
    cta: {
      label: 'Return to Home',
      href: '/',
    },
  },
};

export const ErrorPage = () => {
  const error = useRouteError();

  const { title, description, cta } =
    isRouteErrorResponse(error) &&
    Object.keys(errorMessages).includes(error?.status.toString())
      ? errorMessages[error.status.toString()]
      : errorMessages.default;

  return (
    <div>
      <header className="flex items-center justify-between w-full px-8 py-3 bg-white border-b">
        <Link className="flex items-center" to="/">
          <Branding brandingType={BrandingTypes.SafetyInverted} />
        </Link>
      </header>
      <section className="flex flex-col items-center justify-center w-full h-full mt-8">
        <h1 className="text-4xl font-bold">{title}</h1>
        <p className="mt-4 text-lg">{description}</p>
        <a className="mt-8 text-blue-500 hover:underline" href={cta.href}>
          {cta.label}
        </a>
      </section>
    </div>
  );
};
