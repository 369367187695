import ReactMarkdown from 'react-markdown';
import { Chip, TableBasic } from '@nirvana/ui-kit';
import { analytics } from 'src/helpers';
import Accordion from 'src/components/accordion';
import { FlagCategory, FlagSeverity } from 'src/types/graphql-types';
import { useAnalytics } from 'src/helpers/analytics';
import { getCategoryLabel } from '../constants/flag-category';

type FlagDetails = {
  id: string;
  title: string;
  category: FlagCategory;
  description: string;
  severity: FlagSeverity;
  chart?: any;
};

type FlagAccordionProps = {
  data: FlagDetails;
  expanded: boolean;
};

export default function FlagAccordion({ data, expanded }: FlagAccordionProps) {
  const { trackEvent } = useAnalytics();
  const handleSummaryClick = (isOpen: boolean) => {
    trackEvent({
      event: isOpen
        ? analytics.EventTrack.FlagDetailsExpand
        : analytics.EventTrack.FlagDetailsCollapse,
      properties: {
        flagId: data.id,
      },
    });
  };

  const chartHeaders =
    data.chart?.table?.records?.[0].map((title: string) => {
      return {
        key: title,
        content: title,
      };
    }) ?? [];
  const chartData =
    data.chart?.table?.records?.slice(1).map((rows: Array<string>) => ({
      columns: rows.map((column) => ({ content: column })),
    })) ?? [];

  return (
    <Accordion expanded={expanded}>
      <Accordion.Summary id={data.id} onClick={handleSummaryClick}>
        <div className="grid flex-grow grid-cols-12">
          <p className="col-span-9 text-sm">{data.title}</p>
          <div className="col-span-2">
            <Chip color="tint" label={getCategoryLabel(data.category)} />
          </div>
          <div className="col-span-1">
            {(() => {
              switch (data.severity) {
                case FlagSeverity.Critical:
                  return <Chip color="error" label={data.severity} uppercase />;
                case FlagSeverity.Moderate:
                  return (
                    <Chip color="warning" label={data.severity} uppercase />
                  );
                case FlagSeverity.Minor:
                  return <Chip color="info" label={data.severity} uppercase />;
              }
            })()}
          </div>
        </div>
      </Accordion.Summary>
      <Accordion.Details>
        <div className="prose-sm prose max-w-none prose-slate">
          <ReactMarkdown>{data.description}</ReactMarkdown>
          {chartData.length ? (
            <div className="not-prose">
              <p className="mt-2 mb-2 text-base font-bold">
                {data.chart.title}
              </p>
              <TableBasic headers={chartHeaders} rows={chartData} />
            </div>
          ) : null}
        </div>
      </Accordion.Details>
    </Accordion>
  );
}
