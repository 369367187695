import { useContext } from 'react';
import {
  Divider,
  InputLabel,
  OutlinedInput,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { NewClaimContext } from '../contexts/newClaim';
import { noticeTypeOptions } from '../constants';

export const ReporterInformation = () => {
  const { formState, register } = useContext(NewClaimContext);

  const invalidPhone = !!formState.errors.reporter?.phone;
  const invalidEmail = !!formState.errors.reporter?.email;
  return (
    <div className="flex flex-col">
      <h3 className="text-base text-3xl font-bold">Reporter Information</h3>

      <div className="flex flex-col w-full p-8 mt-8 bg-white rounded-lg shadow">
        <div className="flex flex-col flex-1">
          <p className="text-base font-medium text-md">Contact Information</p>
          <p className="mt-4 text-sm font-normal text-text-hint">
            Please verify that the contact details are correct. We will use
            these details to get in touch with you about the claim.
          </p>
        </div>

        <div className="w-full max-w-xl mt-4">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex-1">
              <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                First Name
              </InputLabel>
              <OutlinedInput {...register('reporter.firstName')} fullWidth />
            </div>

            <div className="flex-1 mt-4 ml-0 md:mt-0 md:ml-6">
              <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                Last Name
              </InputLabel>
              <OutlinedInput {...register('reporter.lastName')} fullWidth />
            </div>
          </div>

          <div className="flex flex-col mt-4 md:flex-row md:justify-between">
            <div className="flex-1">
              <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                Phone
              </InputLabel>
              <OutlinedInput
                {...register('reporter.phone')}
                error={invalidPhone}
                fullWidth
                placeholder="1234567890"
              />
              {invalidPhone && (
                <span className="text-xs text-error-main">Invalid format</span>
              )}
            </div>

            <div className="flex-1 mt-4 ml-0 md:mt-0 md:ml-6">
              <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
                Email Address
              </InputLabel>
              <OutlinedInput
                error={invalidEmail}
                {...register('reporter.email')}
                fullWidth
              />
              {invalidEmail && (
                <span className="text-xs text-error-main">Invalid format</span>
              )}
            </div>
          </div>
        </div>

        <Divider className="my-6" />

        <p className="text-base font-medium text-md">Insured Information</p>
        <div className="flex w-full mt-4">
          <div className="flex-1">
            <InputLabel className="mb-2 text-sm font-normal text-primary-main text-normal">
              Insured Company Name
            </InputLabel>
            <OutlinedInput {...register('insuredName')} fullWidth />
          </div>

          <div className="flex-1 hidden ml-4 md:block" />
        </div>

        <Divider className="my-6" />

        <div className="flex flex-col flex-1">
          <p className="text-base font-medium text-md">Notice Type</p>
          <p className="mt-4 text-sm font-normal text-text-hint">
            Let us know if you want to just report this incident or initiate a
            claim.
          </p>
        </div>

        <div className="mt-4">
          <RadioGroup row defaultValue="initiateClaim">
            {noticeTypeOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                {...register('noticeType')}
                control={<Radio />}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroup>
        </div>
      </div>
    </div>
  );
};
