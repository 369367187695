import SafetyScore from './components/safety-score';
import ISSScore from './components/iss-score';
import BasicScore from './components/basic-score';

export default function Scores() {
  return (
    <div className="space-y-5">
      <SafetyScore />
      <ISSScore />
      <BasicScore />
    </div>
  );
}
