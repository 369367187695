import { Tsp } from 'src/types/graphql-types';
import { safetyScoresFactory } from '../../../../cypress/support/factories/safetyScoresFactory';
import { issScoresFactory } from '../../../../cypress/support/factories/issScoresFactory';
import { inspectionsFactory } from '../../../../cypress/support/factories/inspectionsFactory';
import { mockLocationStats } from './mockLocationStats';

const MOCK_DOT_NUMBER = '123456';
const MOCK_FLEET_NAME = 'NIRVANA TRUCKING';
const MOCK_FLEET_REPORT_ID = 'c5a7c2e1-d41f-4e9f-a5f4-13293013b296';

export const mockDataRecord = (userID: string): Record<string, any> => {
  return {
    ViolationsWithDrivers: {
      data: {
        fleetSafetyReport: {
          __key: MOCK_FLEET_REPORT_ID,
          __typename: 'fleetSafetyReport',
          datagovInspections: [],
          dotNumber: MOCK_DOT_NUMBER,
          fmcsaAuthStatus: 'Undefined',
          inspections: inspectionsFactory.build(undefined, {
            transient: {
              addDrivers: true,
            },
          }),
          id: MOCK_FLEET_REPORT_ID,
        },
      },
      errors: null,
    },
    InitiateDriverViolationFetch: {
      data: {
        initiateDriverViolationFetch: {
          browserWSEndpoint: 'ws://localhost:3000',
          twoFactorUrl: 'http://localhost:3000',
        },
      },
      errors: [],
    },
    CompleteDriverViolationFetch: {
      data: {
        completeDriverViolationFetch: {
          success: true,
        },
      },
      errors: [],
    },
    Accident: {
      data: {
        fleetSafetyReport: {
          __key: MOCK_FLEET_REPORT_ID,
          __typename: 'fleetSafetyReport',
          OOSSummary: [
            {
              __typename: 'OOSSummary',
              category: 'Overall',
              inspections: 107,
              nationalAverage: 18.46,
              oOSPercent: 28.037383177570092,
              oOSViolations: 30,
            },
            {
              __typename: 'OOSSummary',
              category: 'Vehicle',
              inspections: 63,
              nationalAverage: 21.07,
              oOSPercent: 36.507936507936506,
              oOSViolations: 23,
            },
            {
              __typename: 'OOSSummary',
              category: 'Driver',
              inspections: 107,
              nationalAverage: 5.72,
              oOSPercent: 7.4766355140186915,
              oOSViolations: 8,
            },
          ],
          basicScores: [],
          id: MOCK_FLEET_REPORT_ID,
        },
      },
      errors: null,
    },
    getBasicRecommendation: {
      data: {
        fleetSafetyReport: {
          __key: MOCK_FLEET_REPORT_ID,
          __typename: 'fleetSafetyReport',
          id: MOCK_FLEET_REPORT_ID,
          recommendations: {
            __typename: 'RecommendationConnection',
            edges: [
              {
                __typename: 'RecommendationEdge',
                node: {
                  __key: 'VehicleOutOfServiceExceedsNationalAverage_3452571',
                  __typename: 'recommendation',
                  sections: [
                    {
                      __typename: 'RecommendationSection',
                      reasons: [],
                    },
                    {
                      __typename: 'RecommendationSection',
                      reasons: [],
                    },
                  ],
                },
              },
              {
                __typename: 'RecommendationEdge',
                node: {
                  __key: 'vehiclemaintenance_alerting_3452571',
                  __typename: 'recommendation',
                  sections: [
                    {
                      __typename: 'RecommendationSection',
                      reasons: [
                        {
                          __typename: 'Reason',
                          basicCategory: 'Vehicle Maintenance',
                          link: '/b28328d0-4598-488a-8852-2477f9b6c09e/insights/violations?group_name=BrakesOutOfAdjustment,BrakesAllOthers',
                          vINs: [],
                          violationCount: 26,
                          violationGroup: 'Brakes',
                          weightage: 34.29951690821256,
                        },
                      ],
                    },
                  ],
                },
              },
              {
                __typename: 'RecommendationEdge',
                node: {
                  __key: 'unsafedriving_warning_2834206',
                  __typename: 'recommendation',
                  sections: [
                    {
                      __typename: 'RecommendationSection',
                      reasons: [
                        {
                          __typename: 'Reason',
                          basicCategory: 'Unsafe Driving',
                          link: '/b28328d0-4598-488a-8852-2477f9b6c09e/insights/violations?group_name=Speeding3,Speeding2,Speeding4',
                          vINs: [],
                          violationCount: 14,
                          violationGroup: 'Speeding',
                          weightage: 54.14847161572053,
                        },
                      ],
                    },
                    {
                      __typename: 'RecommendationSection',
                      reasons: [
                        {
                          __typename: 'Reason',
                          basicCategory: 'Unsafe Driving',
                          link: '/b28328d0-4598-488a-8852-2477f9b6c09e/insights/violations?group_name=DangerousDriving,RecklessDriving',
                          vINs: [],
                          violationCount: 8,
                          violationGroup: 'Dangerous / Reckless Driving',
                          weightage: 24.017467248908297,
                        },
                      ],
                    },
                  ],
                },
              },
              {
                __typename: 'RecommendationEdge',
                node: {
                  __key: 'hoscompliance_alerting_3452571',
                  __typename: 'recommendation',
                  sections: [
                    {
                      __typename: 'RecommendationSection',
                      reasons: [
                        {
                          __typename: 'Reason',
                          basicCategory: 'HOS Compliance',
                          link: '/b28328d0-4598-488a-8852-2477f9b6c09e/insights/violations?group_name=FalseLog',
                          vINs: [],
                          violationCount: 3,
                          violationGroup: 'False Log',
                          weightage: 45.26315789473684,
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        },
      },
      errors: null,
    },
    getBasicScores: {
      data: {
        fleetSafetyReport: {
          __key: MOCK_FLEET_REPORT_ID,
          __typename: 'fleetSafetyReport',
          basicScores: [
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-09-27',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 1.69,
                  name: 'Unsafe Driving',
                  percentile: 16,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.8,
                  name: 'HOS Compliance',
                  percentile: 64,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 7.41,
                  name: 'Vehicle Maintenance',
                  percentile: 85,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.23,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.24545454545454548,
                  name: 'Crash Indicator',
                  percentile: 47,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-08-30',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 2.09,
                  name: 'Unsafe Driving',
                  percentile: 22,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.73,
                  name: 'HOS Compliance',
                  percentile: 60,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 7.55,
                  name: 'Vehicle Maintenance',
                  percentile: 86,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.21,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.2727272727272727,
                  name: 'Crash Indicator',
                  percentile: 52,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-07-26',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 1.93,
                  name: 'Unsafe Driving',
                  percentile: 20,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.89,
                  name: 'HOS Compliance',
                  percentile: 66,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 7.3,
                  name: 'Vehicle Maintenance',
                  percentile: 85,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.19,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.31304347826086953,
                  name: 'Crash Indicator',
                  percentile: 57,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-06-28',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 1.93,
                  name: 'Unsafe Driving',
                  percentile: 20,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 1,
                  name: 'HOS Compliance',
                  percentile: 70,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 7.38,
                  name: 'Vehicle Maintenance',
                  percentile: 85,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.17,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.3652173913043478,
                  name: 'Crash Indicator',
                  percentile: 64,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-05-31',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 2.82,
                  name: 'Unsafe Driving',
                  percentile: 42,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.92,
                  name: 'HOS Compliance',
                  percentile: 67,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 7.45,
                  name: 'Vehicle Maintenance',
                  percentile: 86,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.25,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.42,
                  name: 'Crash Indicator',
                  percentile: 70,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-04-26',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 2.85,
                  name: 'Unsafe Driving',
                  percentile: 42,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.86,
                  name: 'HOS Compliance',
                  percentile: 65,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 8.26,
                  name: 'Vehicle Maintenance',
                  percentile: 90,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.27,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.4,
                  name: 'Crash Indicator',
                  percentile: 68,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-03-29',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 3.28,
                  name: 'Unsafe Driving',
                  percentile: 49,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.8,
                  name: 'HOS Compliance',
                  percentile: 62,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 8.41,
                  name: 'Vehicle Maintenance',
                  percentile: 90,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.25,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.45714285714285713,
                  name: 'Crash Indicator',
                  percentile: 74,
                  threshold: 65,
                },
              ],
            },
          ],
          dotNumber: MOCK_DOT_NUMBER,
          id: MOCK_FLEET_REPORT_ID,
        },
      },
      errors: null,
    },
    violations: {
      data: {
        fleetSafetyReport: {
          __key: MOCK_FLEET_REPORT_ID,
          __typename: 'fleetSafetyReport',
          datagovInspections: [],
          dotNumber: MOCK_DOT_NUMBER,
          fmcsaAuthStatus: 'Undefined',
          inspections: inspectionsFactory.build(),
          id: MOCK_FLEET_REPORT_ID,
        },
      },
      errors: null,
    },
    getViolationMapData: {
      data: {
        fleetSafetyReport: {
          LocationStats: mockLocationStats,
          __key: 'c5a7c2e1-d41f-4e9f-a5f4-13293013b296',
          __typename: 'fleetSafetyReport',
          basicScores: [
            {
              __typename: 'basicScore',
              scoreDate: '2024-09-27',
            },
            {
              __typename: 'basicScore',
              scoreDate: '2024-08-30',
            },
            {
              __typename: 'basicScore',
              scoreDate: '2024-07-26',
            },
          ],
          dotNumber: '123456',
          fmcsaAuthStatus: 'Undefined',
          id: 'c5a7c2e1-d41f-4e9f-a5f4-13293013b296',
        },
      },
      errors: null,
    },
    safetyReport: {
      data: {
        fleetSafetyReport: {
          __key: MOCK_FLEET_REPORT_ID,
          __typename: 'fleetSafetyReport',
          createdBy: userID,
          dotNumber: MOCK_DOT_NUMBER,
          id: MOCK_FLEET_REPORT_ID,
        },
      },
      errors: [],
    },
    fleetRating: {
      data: {
        fleetSafetyReport: {
          __key: MOCK_FLEET_REPORT_ID,
          __typename: 'fleetSafetyReport',
          basicScores: [
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-09-27',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 1.69,
                  name: 'Unsafe Driving',
                  percentile: 16,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.8,
                  name: 'HOS Compliance',
                  percentile: 64,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 7.41,
                  name: 'Vehicle Maintenance',
                  percentile: 85,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.23,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.24545454545454548,
                  name: 'Crash Indicator',
                  percentile: 47,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-08-30',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 2.09,
                  name: 'Unsafe Driving',
                  percentile: 22,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.73,
                  name: 'HOS Compliance',
                  percentile: 60,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 7.55,
                  name: 'Vehicle Maintenance',
                  percentile: 86,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.21,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.2727272727272727,
                  name: 'Crash Indicator',
                  percentile: 52,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-07-26',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 1.93,
                  name: 'Unsafe Driving',
                  percentile: 20,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.89,
                  name: 'HOS Compliance',
                  percentile: 66,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 7.3,
                  name: 'Vehicle Maintenance',
                  percentile: 85,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.19,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.31304347826086953,
                  name: 'Crash Indicator',
                  percentile: 57,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-06-28',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 1.93,
                  name: 'Unsafe Driving',
                  percentile: 20,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 1,
                  name: 'HOS Compliance',
                  percentile: 70,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 7.38,
                  name: 'Vehicle Maintenance',
                  percentile: 85,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.17,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.3652173913043478,
                  name: 'Crash Indicator',
                  percentile: 64,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-05-31',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 2.82,
                  name: 'Unsafe Driving',
                  percentile: 42,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.92,
                  name: 'HOS Compliance',
                  percentile: 67,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 7.45,
                  name: 'Vehicle Maintenance',
                  percentile: 86,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.25,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.42,
                  name: 'Crash Indicator',
                  percentile: 70,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-04-26',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 2.85,
                  name: 'Unsafe Driving',
                  percentile: 42,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.86,
                  name: 'HOS Compliance',
                  percentile: 65,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 8.26,
                  name: 'Vehicle Maintenance',
                  percentile: 90,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.27,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.4,
                  name: 'Crash Indicator',
                  percentile: 68,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-03-29',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 3.28,
                  name: 'Unsafe Driving',
                  percentile: 49,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.8,
                  name: 'HOS Compliance',
                  percentile: 62,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 8.41,
                  name: 'Vehicle Maintenance',
                  percentile: 90,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.25,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.45714285714285713,
                  name: 'Crash Indicator',
                  percentile: 74,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-02-23',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 3.57,
                  name: 'Unsafe Driving',
                  percentile: 54,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.79,
                  name: 'HOS Compliance',
                  percentile: 62,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 8.06,
                  name: 'Vehicle Maintenance',
                  percentile: 89,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.24,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.45714285714285713,
                  name: 'Crash Indicator',
                  percentile: 74,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2024-01-26',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 3.51,
                  name: 'Unsafe Driving',
                  percentile: 53,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.39,
                  name: 'HOS Compliance',
                  percentile: 40,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 8.1,
                  name: 'Vehicle Maintenance',
                  percentile: 89,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.22,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.6,
                  name: 'Crash Indicator',
                  percentile: 81,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2023-12-29',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 3.6,
                  name: 'Unsafe Driving',
                  percentile: 54,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.39,
                  name: 'HOS Compliance',
                  percentile: 39,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 8.22,
                  name: 'Vehicle Maintenance',
                  percentile: 90,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.22,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.44999999999999996,
                  name: 'Crash Indicator',
                  percentile: 73,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2023-11-24',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 3,
                  name: 'Unsafe Driving',
                  percentile: 45,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.42,
                  name: 'HOS Compliance',
                  percentile: 41,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 8.87,
                  name: 'Vehicle Maintenance',
                  percentile: 92,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.31,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.5769230769230769,
                  name: 'Crash Indicator',
                  percentile: 80,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2023-10-27',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 3.41,
                  name: 'Unsafe Driving',
                  percentile: 51,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.42,
                  name: 'HOS Compliance',
                  percentile: 42,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 9.08,
                  name: 'Vehicle Maintenance',
                  percentile: 93,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.35,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.47368421052631576,
                  name: 'Crash Indicator',
                  percentile: 73,
                  threshold: 65,
                },
              ],
            },
            {
              __typename: 'basicScore',
              discount: 7.5,
              scoreDate: '2023-09-29',
              scores: [
                {
                  __typename: 'BasicScoreDetail',
                  measure: 3.43,
                  name: 'Unsafe Driving',
                  percentile: 52,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.43,
                  name: 'HOS Compliance',
                  percentile: 43,
                  threshold: 65,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 9.01,
                  name: 'Vehicle Maintenance',
                  percentile: 92,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'Controlled Substances/Alcohol',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0,
                  name: 'HazMat Compliance',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.36,
                  name: 'Driver Fitness',
                  percentile: null,
                  threshold: 80,
                },
                {
                  __typename: 'BasicScoreDetail',
                  measure: 0.5368421052631579,
                  name: 'Crash Indicator',
                  percentile: 78,
                  threshold: 65,
                },
              ],
            },
          ],
          dotNumber: MOCK_DOT_NUMBER,
          id: MOCK_FLEET_REPORT_ID,
          issScores: issScoresFactory.build(),
        },
      },
      errors: [],
    },
    FleetDetails: {
      data: {
        fleetSafetyReport: {
          __key: MOCK_FLEET_REPORT_ID,
          __typename: 'fleetSafetyReport',
          createdBy: userID,
          dotNumber: MOCK_DOT_NUMBER,
          fmcsaAuthStatus: 'Undefined',
          hasTelematicsConnection: true,
          TspProvider: Tsp.TspKeepTruckin,
          id: MOCK_FLEET_REPORT_ID,
          name: MOCK_FLEET_NAME,
          starred: true,
        },
      },
      errors: [],
    },
    telematicsRiskScore: {
      data: {
        fleetSafetyReport: {
          __key: MOCK_FLEET_REPORT_ID,
          __typename: 'fleetSafetyReport',
          TspProvider: 'TSPSamsara',
          dotNumber: MOCK_DOT_NUMBER,
          hasTelematicsConnection: true,
          id: MOCK_FLEET_REPORT_ID,
          telematicsRiskFleetPercentiles: safetyScoresFactory.build(),
        },
      },
      errors: [],
    },
  };
};
