import { useCallback, useEffect } from 'react';
import qs from 'query-string';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import Navbar from 'src/components/navbar';
import { tspProviders } from 'src/constants';
import CircleIcon from 'src/assets/icons/circle.svg?react';
import { useFinalizeTelematicsMutation } from 'src/types/graphql-types';
import SquareIcon from 'src/assets/icons/squares.svg?react';
import RiskManagementIcon from 'src/assets/icons/risk-management.svg?react';
import { firstParam } from './utils';

export default function TelematicsComplete() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const searchParams = qs.parse(search, { arrayFormat: 'comma' });
  const [queryProvider] = firstParam(['provider'], searchParams);
  const provider = tspProviders.find(
    (val) => val.key.toLowerCase() === (queryProvider ?? '').toLowerCase(),
  );

  const [finalizeTelematics, { data }] = useFinalizeTelematicsMutation({
    onCompleted: ({ finalizeTelematics }) => {
      const { safetyReportID, error } = finalizeTelematics ?? {};
      if (safetyReportID) {
        navigate('/' + safetyReportID);
        enqueueSnackbar('Successfully linked Telematics connection!', {
          variant: 'success',
        });
      } else {
        navigate('/search');
        enqueueSnackbar(error ?? 'Unexpected error linking telematics', {
          variant: 'error',
        });
      }
    },
    // TODO: we should create a helper function that returns the appropriate
    // Network error / client safe error / internal server error message.
    onError: () => {
      enqueueSnackbar(
        'There was an unexpected error connecting telematics, please try again later',
        { variant: 'error', onClose: () => navigate('/search') },
      );
    },
  });

  const handleClose = useCallback(() => {
    const safetyReportID = data?.finalizeTelematics?.safetyReportID;
    if (safetyReportID) {
      navigate('/' + safetyReportID + '/overview');
    } else {
      navigate('/search');
    }
  }, [data?.finalizeTelematics?.safetyReportID, navigate]);

  useEffect(() => {
    const [state, code, scope, error] = firstParam(
      ['state', 'code', 'scope', 'error'],
      searchParams,
    );

    if (error) {
      enqueueSnackbar(error, { variant: 'error', onClose: handleClose });
    } else if (!provider) {
      enqueueSnackbar(
        "Unable to make telematics link, missing 'provider' response value from telematics provider",
        { variant: 'error', onClose: handleClose },
      );
    } else if (!state) {
      enqueueSnackbar(
        "Unable to link telematics, missing 'state' response value from telematics provider.",
        { variant: 'error', onClose: handleClose },
      );
    } else if (!code) {
      enqueueSnackbar(
        "Unable to link telematics, missing 'code' response value from telematics provider.",
        { variant: 'error', onClose: handleClose },
      );
    } else {
      finalizeTelematics({
        variables: {
          authCode: code,
          error: error,
          tsp: provider.key,
          state: state,
          scope: scope,
        },
      });
    }
    /**  Adding dependency array is causing this side-effect to execute multiple times
     * resulting in multiple API requests. We only need this to logic to execute once
     * on component mount
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex flex-col w-screen h-screen overflow-hidden bg-gray-50">
      <Navbar />
      <div className="absolute top-0 left-0 right-0 z-0 overflow-hidden h-96 bg-primary-extraLight">
        <CircleIcon className="absolute w-32 h-32 -left-24 top-16" />
        <CircleIcon className="absolute w-16 h-16 -right-4 bottom-6" />
        <CircleIcon className="absolute w-8 h-8 -right-4 top-1/2" />
        <CircleIcon className="absolute left-16 bottom-16" />
      </div>
      <div className="relative flex flex-col flex-1 w-full max-w-3xl px-1 pb-3 mx-auto overflow-hidden 2xl:max-w-4xl pt-14">
        <div className="relative flex flex-col items-start">
          <p className="max-w-md mb-6 text-4xl font-semibold text-left text-text-primary">
            Connecting Telematics ...
          </p>
          <RiskManagementIcon className="absolute -right-16 -top-16" />
          <SquareIcon className="absolute top-0 left-2/3" />
        </div>
        <div className="relative flex flex-row items-center">
          <CircularProgress size={30} className="mr-3 text-slate-600" />
          <span className="mr-3 text-2xl font-semibold text-slate-600">
            Syncing with
          </span>
          {provider && <img src={provider.logo} alt={provider.alt} />}
        </div>
      </div>
    </div>
  );
}
