import { useEffect } from 'react';
import { useBlocker, useLocation } from 'react-router-dom';
import { useAnalytics } from 'src/helpers/analytics';

export default function Pageview() {
  const location = useLocation();
  const { trackPageView, trackPageLeave } = useAnalytics();
  useBlocker(() => {
    trackPageLeave();
    return false;
  });

  useEffect(() => {
    trackPageView(location.pathname + location.search);
    // We use location on the useEffect to trigger the pageview every time the url changes
  }, [location, trackPageView]);

  return null;
}
