import Zones from '../../components/zones';

const Heatmap = () => {
  return (
    <div className="mb-5">
      <Zones />
    </div>
  );
};

export default Heatmap;
