import { useEffect } from 'react';
import {
  useGetUserContactInfoQuery,
  useUpdateUserContactInfoMutation,
} from 'src/types/graphql-types';
import { Controller, useForm } from 'react-hook-form';
import { InputWithLabel } from '@nirvana/ui-kit';
import useAuth from 'src/hooks/useAuth';
import { Button, FormControl } from '@material-ui/core';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import SettingsBlock from './settings-block';

// This phone number regex will match the following formats (among others):
// 2234567890
// 223-456-7890
// 223.456.7890
// 223 456 7890
// (223) 456 7890
// (223)-456-7890
// (223)456-7890
// +91 (223) 456-7890

const VALID_PHONE_NUMBER_REGEX =
  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const ContactInfoSchema = z.object({
  phoneNumber: z
    .string()
    .regex(VALID_PHONE_NUMBER_REGEX, 'Invalid Number!')
    .optional()
    .or(z.literal('')),
  firstName: z.string().min(1, 'Please enter your first name'),
  lastName: z.string().min(1, 'Please enter your last name'),
});

type ContactInfoType = z.infer<typeof ContactInfoSchema>;

export default function ContactInfo() {
  const { user } = useAuth();

  const { data } = useGetUserContactInfoQuery({
    variables: {
      userId: user?.id,
    },
  });

  const [patchUser] = useUpdateUserContactInfoMutation({});

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
    reset,
  } = useForm<ContactInfoType>({
    mode: 'onChange',
    resolver: zodResolver(ContactInfoSchema),
  });

  useEffect(() => {
    if (data) {
      reset({
        phoneNumber: data?.user?.phoneNumber || undefined,
        firstName: data?.user?.firstName,
        lastName: data?.user?.lastName,
      });
    }
  }, [data, reset]);

  const onSubmit = (formData: ContactInfoType) => {
    patchUser({
      variables: {
        id: user!.id,
        phoneNumber: formData.phoneNumber,
        firstName: formData.firstName,
        lastName: formData.lastName,
      },
      onCompleted: (data) => {
        if (data) {
          reset({
            phoneNumber: data.patchUser?.phoneNumber || undefined,
          });
        }
      },
    });
  };

  return (
    <SettingsBlock title="Contact Info">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="md:w-8/12">
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <div>
                <InputWithLabel
                  {...register('firstName')}
                  label="First Name"
                  formControlProps={{ fullWidth: true, className: 'mb-6' }}
                  placeholder="First Name"
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              </div>
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <div>
                <InputWithLabel
                  {...register('lastName')}
                  label="Last Name"
                  formControlProps={{ fullWidth: true, className: 'mb-6' }}
                  placeholder="Last Name"
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              </div>
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field }) => (
              <div>
                <InputWithLabel
                  {...register('phoneNumber')}
                  label="Phone Number"
                  formControlProps={{ fullWidth: true, className: 'mb-6' }}
                  placeholder="Phone Number"
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber?.message}
                />
              </div>
            )}
          />
        </div>
        <div className="w-1/4 ml-auto mb-6">
          <FormControl fullWidth>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isValid}
            >
              Save
            </Button>
          </FormControl>
        </div>
      </form>
    </SettingsBlock>
  );
}
