import { Button } from '@material-ui/core';
import BulbIcon from 'src/assets/icons/bulb.svg?react';
import { analytics } from 'src/helpers';
import { useAnalytics } from 'src/helpers/analytics';

export const NoAssignedDrivers = () => {
  const { trackEvent } = useAnalytics();
  return (
    <div className="mx-auto text-center max-w-72">
      <BulbIcon className="mx-auto" />
      <h2 className="my-4 text-lg font-semibold leading-8 text-text-primary">
        Assign drivers to access driver-level data, analytics and more!
      </h2>
      <p className="mb-4 text-text-hint">
        To assign a driver, you’ll need to assign drivers to vehicles in your
        Telematics Provider’s platform.
      </p>
      <a
        href="https://nvna.info/kb-samsara-assign-driver-to-vehicle"
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          trackEvent({
            event: analytics.EventTrack.ShowMeHowAssignDriver,
          });
        }}
      >
        <Button size="small" variant="contained">
          Show me how
        </Button>
      </a>
    </div>
  );
};
