import { useState } from 'react';
import { Button } from '@material-ui/core';
import ConnectTspModal from 'src/components/connect-tsp-modal';
import TelematicsInsightsIcon from 'src/assets/icons/telematics-insights.svg?react';

export default function TelematicsOverlay() {
  const [showTelematicsModal, setShowTelematicsModal] = useState(false);

  function handleShowTelematicsModal() {
    setShowTelematicsModal(true);
  }

  function handleCloseTelematicsModal() {
    setShowTelematicsModal(false);
  }

  return (
    <div className="absolute inset-0 z-10 flex flex-col items-center justify-center px-12 space-y-4 text-center backdrop-blur-md">
      <TelematicsInsightsIcon />
      <p className="text-xl font-normal text-secondary-main">
        Unlock your Nirvana Safety Score
      </p>
      <p className="text-xs font-normal leading-5 text-text-hint">
        Connect telematics to see the industry&apos;s first risk score based on
        driving behavior
      </p>
      <span>
        <Button variant="outlined" onClick={handleShowTelematicsModal}>
          Connect
        </Button>
      </span>
      <ConnectTspModal
        open={showTelematicsModal}
        onClose={handleCloseTelematicsModal}
      />
    </div>
  );
}
