import { Button, Link } from '@material-ui/core';
import { HiOutlineChevronRight } from 'react-icons/hi';
import CheckCircleTwoTone from '@material-ui/icons/CheckCircleTwoTone';
import TriangleBackground from 'src/assets/icons/triangle-background.svg';
import PerksBackground from 'src/assets/images/perks-background.png';
import { analytics } from 'src/helpers';
import { useAnalytics } from 'src/helpers/analytics';

export default function Perks() {
  const { trackEvent } = useAnalytics();
  const lineItems = [
    'Safety platforms and equipment',
    'Tires',
    'Compliance and Hiring platform',
  ];
  const logEvent = () => {
    trackEvent({
      event: analytics.EventTrack.PerksLinkClicked,
    });
  };

  return (
    <div
      style={{
        width: '100%',
        backgroundImage: `url("${PerksBackground}")`,
        backgroundPosition: 'top left',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        style={{
          backgroundImage: `url("${TriangleBackground}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top left',
        }}
      >
        <div className="pt-5 pl-10 pb-80">
          <p className="mb-4 text-3xl font-bold text-gray-800">
            Nirvana Partner Perks
          </p>
          <div className="mb-6 text-base">
            Get more from your fleet insurance. Save up to $4,000
            <br />
            additional per truck, per year with discounts on:
          </div>
          {lineItems.map((text, index) => {
            return (
              <div
                key={`cell-${index}`}
                className="flex items-center mb-6 space-x-2 text-base"
              >
                <CheckCircleTwoTone
                  style={{ fontSize: '25px', color: '#49E662' }}
                />
                <span>{text}</span>
              </div>
            );
          })}
          <div className="flex items-center mb-6 space-x-2 text-base">
            ...and more!
          </div>
          <Link
            href="https://www.nirvanatech.com/perks"
            target="_blank"
            underline="none"
            onClick={logEvent}
          >
            <Button
              variant="contained"
              className="bg-primary-main"
              endIcon={<HiOutlineChevronRight />}
            >
              <span className="text-white"> See Offers </span>
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
