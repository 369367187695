import subMonths from 'date-fns/subMonths';
import {
  Chip,
  getFormattedDateInTimezone,
  LineChart,
  Show,
} from '@nirvana/ui-kit';

import addMonths from 'date-fns/addMonths';
import { FiCalendar, FiAlertCircle, FiBox, FiClock } from 'react-icons/fi';
import { useMemo } from 'react';
import { Column } from '@tanstack/react-table';
import { Feature, useFeatureValue } from 'src/helpers/feature-flags';
import { analytics } from 'src/helpers';
import { useAnalytics } from 'src/helpers/analytics';
import { JointInspectionsData } from '../constants/columns';

type ChartJointInspectionData = {
  dateInSeconds: number;
  formattedInspectionDate: string;
  violations: JointInspectionsData[];
  ponderatedWeight: number;
};

export function CustomTooltip(props: any) {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    const data = payload[0].payload as ChartJointInspectionData;
    return (
      <div className="max-w-xs p-4 bg-white rounded-lg shadow-lg">
        <div className="flex items-center mb-2">
          <FiCalendar className="mr-2 text-gray-500" />
          <p className="flex items-center text-gray-700">
            Date:{' '}
            <Chip
              label={getFormattedDateInTimezone(
                new Date(data.dateInSeconds).toISOString(),
              )}
              className="ml-2"
            />
          </p>
        </div>

        <div className="flex items-center mb-2">
          <FiAlertCircle className="mr-2 text-gray-500" />
          <p className="flex items-center text-gray-700">
            Violations: <Chip label={data.violations.length} className="ml-2" />
          </p>
        </div>

        <div className="flex items-center mb-2">
          <FiBox className="mr-2 text-gray-500" />
          <p className="flex items-center text-gray-700">
            Total Weight :{' '}
            <Chip label={data.ponderatedWeight} className="ml-2" />
          </p>
        </div>

        <div className="flex items-center">
          <FiClock className="mr-2 text-gray-500" />
          <p className="flex items-center text-gray-700">
            Expected Dropoff:{' '}
            <Chip
              label={getFormattedDateInTimezone(
                addMonths(new Date(data.dateInSeconds), 24).toISOString(),
              )}
              className="ml-2"
            />
          </p>
        </div>
      </div>
    );
  }
  return null;
}

export const ExpiringViolations = ({
  violations,
  dateColumn,
  loading,
}: {
  violations: JointInspectionsData[];
  dateColumn: Column<JointInspectionsData> | undefined;
  loading: boolean;
}) => {
  const referenceDate = useMemo(() => new Date(), []);
  const { trackEvent } = useAnalytics();

  const sixMonthsAgo = subMonths(referenceDate, 6).getTime();
  const twelveMonthsAgo = subMonths(referenceDate, 12).getTime();
  const twentyThreeMonthsAgo = subMonths(referenceDate, 23).getTime();
  const twentyFourMonthsAgo = subMonths(referenceDate, 24).getTime();

  const showExpiringViolations = useFeatureValue(
    Feature.SHOW_EXPIRING_VIOLATIONS,
  );

  const formattedViolations = useMemo(() => {
    return violations
      .sort((a, b) => a.dateInSeconds - b.dateInSeconds)
      .reduce((acc, curr) => {
        const index = acc.findIndex(
          (v) => v.dateInSeconds === curr.dateInSeconds,
        );

        if (index === -1) {
          return [
            ...acc,
            {
              ...curr,
              formattedInspectionDate: curr.formattedInspectionDate,
              violations: [curr],
            },
          ];
        }

        return [
          ...acc.slice(0, index),
          {
            ...acc[index],
            formattedInspectionDate: acc[index].formattedInspectionDate,
            ponderatedWeight:
              acc[index].ponderatedWeight + curr.ponderatedWeight,
            violations: [...acc[index].violations, curr],
          },
          ...acc.slice(index + 1),
        ];
      }, [] as ChartJointInspectionData[]);
  }, [violations]);

  if (!showExpiringViolations) {
    return null;
  }

  return (
    <div className="px-4 pt-4 mb-4 bg-white rounded-md shadow">
      <h3 className="text-lg font-bold">CSA Violation Timeline</h3>
      <p>
        Violations are weighted based on their age. The CSA time-weighting
        system ensures that recent violations have a greater influence on your
        BASIC scores, while older violations gradually have less impact.
        Here&apos;s how the weighting works:
      </p>
      <ul className="pl-4 mt-2 list-disc">
        <li>
          Violations that occurred less than 6 months ago are weighted at 3
          times their severity weight.
        </li>
        <li>
          Violations that occurred between 6 and 12 months ago are weighted at 2
          times their severity weight.
        </li>
        <li>
          Violations that occurred over 12 months ago are weighted at 1 times
          their severity weight.
        </li>
        <li>
          Violations older than 24 months are not included in the BASIC scores.
        </li>
      </ul>
      <div className="mt-4 h-72">
        <Show
          when={!loading}
          fallback={
            <div className="w-full h-full bg-gray-100 roundedh-72 animate-pulse" />
          }
        >
          <LineChart
            data={formattedViolations}
            xField="dateInSeconds"
            yField={['ponderatedWeight']}
            lineConfig={[
              {
                dot: {
                  stroke: '#00A0FF',
                  fill: '#ffffff',
                  r: 4,
                  strokeDasharray: '0',
                  onClick: (data: {
                    payload: ChartJointInspectionData['violations'][0];
                  }) => {
                    trackEvent({
                      event: analytics.EventTrack.CSAViolationsTimelineClicked,
                    });
                    dateColumn?.setFilterValue(data.payload.inspectionDate);
                  },
                  onMouseEnter: () => {
                    trackEvent({
                      event: analytics.EventTrack.CSAViolationsTimelineHovered,
                    });
                  },
                },
                stroke: 'none',
                isAnimationActive: false,
                name: 'Date',
              },
            ]}
            xAxis={{
              tickFormatter: (date: number) =>
                getFormattedDateInTimezone(new Date(date).toISOString()),
              type: 'number',
              label: { value: 'Date' },
              domain: [twentyFourMonthsAgo, referenceDate.getTime()],
              dy: 20,
            }}
            yAxis={{
              label: { value: 'CSA points (Total Weight)', dy: 90 },
              domain: [0, 'dataMax + 5'],
            }}
            referenceLines={[
              {
                x: sixMonthsAgo,
                stroke: 'red',
                strokeWidth: 2,
                ifOverflow: 'extendDomain',
              },
              {
                x: twelveMonthsAgo,
                stroke: 'red',
                strokeWidth: 2,
                ifOverflow: 'extendDomain',
              },
              {
                x: twentyThreeMonthsAgo,
                stroke: 'red',
                strokeWidth: 2,
                ifOverflow: 'extendDomain',
              },
            ]}
            referenceAreas={[
              {
                x1: sixMonthsAgo,
                label: {
                  value: '6 months',
                  fontWeight: 'bold',
                  position: 'bottom',
                },
                ifOverflow: 'extendDomain',
                fill: '#FA3252',
              },
              {
                x1: twelveMonthsAgo,
                x2: sixMonthsAgo,
                label: {
                  value: '6 to 12 months',
                  fontWeight: 'bold',
                  position: 'bottom',
                },
                fill: '#FF823C',
              },
              {
                x1: twentyThreeMonthsAgo,
                x2: twelveMonthsAgo,
                label: {
                  value: '12 to 23 months',
                  fontWeight: 'bold',
                  position: 'bottom',
                },
                ifOverflow: 'extendDomain',
                fill: '#FFDC80',
              },
              {
                x2: twentyThreeMonthsAgo,
                label: {
                  value: 'Expiring',
                  fontWeight: 'bold',
                  position: 'bottom',
                },
                ifOverflow: 'extendDomain',
                fill: '#1ED05A',
              },
            ]}
            tooltipProps={{ content: <CustomTooltip /> }}
          />
        </Show>
      </div>
    </div>
  );
};
