import { getFormattedDateInTimezone } from '@nirvana/ui-kit';
import parseISO from 'date-fns/parseISO';
import setDate from 'date-fns/setDate';
import subMonths from 'date-fns/subMonths';

type Scores = {
  scores: {
    timestamp: string;
  }[];
}[];

export const mostPopularDateForVinPercentiles = (scores?: Scores) => {
  if (!scores) {
    return undefined;
  }
  const datesOfScores = scores.map((RiskVin) => RiskVin.scores[0].timestamp);

  const countOfScoresPerDate = datesOfScores.reduce((acc, date) => {
    acc[date] = (acc[date] ?? 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const sortedCountOfScoresPerDate = Object.entries(countOfScoresPerDate).sort(
    (a, b) => Number(a[1] > b[1]),
  );
  return sortedCountOfScoresPerDate[0]?.[0] || '';
};

export const formatPeriod = (startDate: Date, endDate: Date) => {
  const period = `${getFormattedDateInTimezone(
    startDate.toISOString(),
  )} to ${getFormattedDateInTimezone(endDate.toISOString())}`;
  return period;
};

export const getThreeMonthsPeriodSinceDate = (date?: string) => {
  if (!date) {
    return undefined;
  }
  const endTime = parseISO(date);
  const startTime = setDate(subMonths(endTime, 3), 0);
  return formatPeriod(startTime, endTime);
};
