import { useEffect } from 'react';
import { useAnalytics } from 'src/helpers/analytics';
import useAuth from 'src/hooks/useAuth';

export const Analytics = () => {
  const { user } = useAuth();
  const { trackUserIdentify } = useAnalytics();

  useEffect(() => {
    if (user) {
      trackUserIdentify(user);
    }
  }, [trackUserIdentify, user]);

  return null;
};
