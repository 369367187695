import MitigateRisk from 'src/assets/icons/mitigate-risk.svg?react';
import ClaimIcon from 'src/assets/icons/claim-icon.svg?react';
import SafetyAnalytics from 'src/assets/icons/safety-analytics.svg?react';

export const offerings = [
  {
    icon: MitigateRisk,
    title: 'Improve your Fleet’s Safety',
    description:
      'Actionable insights to  improve your fleet’s safety and performance',
  },
  {
    icon: ClaimIcon,
    title: 'Stay Ahead of DOT',
    description:
      'Personalized recommendations to help you avoid FMCSA interventions',
  },
  {
    icon: SafetyAnalytics,
    title: 'Reduce Insurance Premiums',
    description: 'Customized suggestions that lead to lower insurance premiums',
  },
];
