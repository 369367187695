import { Navigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { useState } from 'react';
import { Checkbox } from '@nirvana/ui-kit';
import { useForm } from 'react-hook-form';
import {
  useGetUserNotificationPreferencesQuery,
  useUpdateUserNotificationPreferencesMutation,
} from 'src/types/graphql-types';
import { Divider } from '@material-ui/core';
import { analytics } from 'src/helpers';
import { useAnalytics } from 'src/helpers/analytics';
import SettingsBlock from './settings-block';

const PreferenceViewConfig: Record<string, any> = {
  RowSettings: {
    'send-violation-alerts': {
      title: 'Violation Alerts',
      description:
        'Get notifications when FMCSA publishes new violations for your fleet.',
    },
  },
  ChannelTypeLabels: {
    email: 'Email',
    sms: 'SMS',
  },
};

interface PreferenceSettingsRowProps {
  preferenceKey: string;
  channelTypeSettings: Record<string, boolean>;
  onChange: (args: {
    preferenceKey: string;
    channelTypeSettings: Record<string, boolean>;
  }) => void;
  register: any;
  handleSubmit: any;
}

// The PrefereceSettingsRow component is what actually displays the UI to manipulate
function PreferenceSettingsRow({
  preferenceKey,
  channelTypeSettings,
  onChange,
  register,
  handleSubmit,
}: PreferenceSettingsRowProps) {
  const { trackEvent } = useAnalytics();
  if (PreferenceViewConfig.RowSettings[preferenceKey] === undefined) {
    return null;
  }
  return (
    <>
      <div className="col-span-12">
        <Divider />
      </div>
      <div className="col-span-8 md:col-span-10">
        <div className="font-bold">
          {PreferenceViewConfig.RowSettings[preferenceKey].title}
        </div>
        <div>
          {' '}
          {PreferenceViewConfig.RowSettings[preferenceKey].description}{' '}
        </div>
      </div>
      {Object.keys(PreferenceViewConfig.ChannelTypeLabels).map(
        (channelType) => {
          return (
            <div
              key={`${preferenceKey}_${channelType}`}
              className="flex justify-between col-span-2 md:col-span-1"
            >
              <Checkbox
                {...register(`${preferenceKey}_${channelType}`)}
                id={`${preferenceKey}_${channelType}`}
                checked={channelTypeSettings[channelType]}
                disabled={
                  typeof channelTypeSettings[channelType] === 'undefined'
                }
                onChange={(e) => {
                  if (e.target.checked === false) {
                    trackEvent({
                      event:
                        analytics.EventTrack.NotificationPreferencesUnsubscribe,
                      properties: {
                        workflow: preferenceKey,
                        channelType: channelType,
                      },
                    });
                  }

                  handleSubmit(
                    onChange({
                      preferenceKey,
                      channelTypeSettings: {
                        ...channelTypeSettings,
                        [channelType]: e.target.checked,
                      },
                    }),
                  );
                }}
              />
            </div>
          );
        },
      )}
    </>
  );
}

interface Workflow {
  workflow: string;
  email: boolean;
  sms: boolean;
}

interface LocalPreferences {
  workflows: Record<
    string,
    {
      channel_types: {
        email: boolean;
        sms: boolean;
      };
    }
  >;
}

const mapPreferences = (workflows: Workflow[]): LocalPreferences => {
  const preferences: LocalPreferences = {
    workflows: workflows.reduce((acc, workflow) => {
      acc[workflow.workflow] = {
        channel_types: {
          email: workflow.email,
          sms: workflow.sms,
        },
      };
      return acc;
    }, {} as Record<string, { channel_types: { email: boolean; sms: boolean } }>),
  };

  if (Object.keys(preferences.workflows).length === 0) {
    // Set default preferences
    return {
      workflows: {
        'send-violation-alerts': {
          channel_types: {
            email: true,
            sms: false,
          },
        },
      },
    };
  }

  return preferences;
};

export const NotificationPreferences = () => {
  const { register, handleSubmit } = useForm();
  const [localPreferences, setLocalPreferences] = useState<LocalPreferences>();

  useGetUserNotificationPreferencesQuery({
    onCompleted: (data) => {
      const preferences = mapPreferences(data.userNotificationPreferences);
      setLocalPreferences(preferences);
    },
  });

  const [updateUserPreferences] = useUpdateUserNotificationPreferencesMutation({
    onCompleted: (data) => {
      const preferences = mapPreferences(
        data.updateUserNotificationPreferences,
      );
      setLocalPreferences(preferences);
    },
  });

  const onPreferenceChange = async ({
    preferenceKey,
    channelTypeSettings,
  }: {
    preferenceKey: string;
    channelTypeSettings: Record<string, boolean>;
  }) => {
    updateUserPreferences({
      variables: {
        preferences: [
          {
            workflow: preferenceKey,
            email: channelTypeSettings.email,
            sms: channelTypeSettings.sms,
          },
        ],
      },
    });
  };

  const { user } = useAuth();
  if (!(user?.userType === 'nirvana' || user?.userType === 'fleet')) {
    return <Navigate to="/" />;
  }

  if (!localPreferences) {
    return null;
  }

  return (
    <SettingsBlock title="Notification Preferences">
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-8 md:col-span-10"> Notification </div>
        {Object.keys(PreferenceViewConfig.ChannelTypeLabels).map(
          (channelType) => {
            return (
              <div
                className="flex justify-between col-span-2 md:col-span-1"
                key={channelType}
              >
                {PreferenceViewConfig.ChannelTypeLabels[channelType]}
              </div>
            );
          },
        )}
        {Object.keys(localPreferences?.workflows).map((workflow) => {
          return (
            <PreferenceSettingsRow
              key={workflow}
              preferenceKey={workflow}
              channelTypeSettings={
                typeof localPreferences?.workflows[workflow] === 'object'
                  ? localPreferences?.workflows[workflow]?.channel_types
                  : {}
              }
              onChange={onPreferenceChange}
              register={register}
              handleSubmit={handleSubmit}
            />
          );
        })}
      </div>
      <p className="mt-5 text-sm">
        By checking the SMS box, you agree to receive SMS messages for
        notification purposes.
      </p>
    </SettingsBlock>
  );
};
