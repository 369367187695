import { Paper } from '@material-ui/core';
import Car from '../../../assets/icons/car.svg?react';
import Document from '../../../assets/icons/document.svg?react';
import Paperclip from '../../../assets/icons/paperclip.svg?react';

export const Overview = () => {
  return (
    <div className="flex flex-col">
      <h3 className="text-3xl font-bold ">Report a Claim</h3>
      <p className="mt-2 text-xl text-text-hint">
        Report an incident or start a claim. The following questions should take
        you about 5 minutes to complete.
      </p>

      <Paper className="p-8 mt-4" elevation={1}>
        <div className="flex flex-col gap-4">
          <p className="text-lg font-bold text-text-secondary">
            Before you begin, please have the following information ready.
          </p>
          <p className="text-base font-normal text-secondary-light">
            If you leave before completing all questions, your information will
            be lost.
          </p>

          <div className="flex flex-row items-center gap-4 my-3">
            <div className="flex items-center justify-center p-2 rounded text-primary-extraLight w-9 h-9">
              <Car />
            </div>
            <div className="flex flex-col">
              <h6 className="text-base font-bold text-text-secondary">
                Vehicle and Driver Details
              </h6>
              <p className="text-sm font-normal text-text-primary">
                VINs, Registration Numbers, Driver Names, Licenses.
              </p>
            </div>
          </div>

          <div className="flex flex-row items-center gap-4 mb-3">
            <div className="flex items-center justify-center p-2 rounded text-primary-extraLight w-9 h-9">
              <Document />
            </div>
            <div className="flex flex-col">
              <h6 className="text-base font-bold text-text-secondary">
                Incident information
              </h6>
              <p className="text-sm font-normal text-text-primary">
                Loss date/time, road conditions, police report number.
              </p>
            </div>
          </div>

          <div className="flex flex-row items-center gap-4 mb-3">
            <div className="flex items-center justify-center p-2 rounded text-primary-extraLight w-9 h-9">
              <Paperclip />
            </div>
            <div className="flex flex-col">
              <h6 className="text-base font-bold text-text-secondary">
                Supporting Reports, Images and Videos
              </h6>
              <p className="text-sm font-normal text-text-primary">
                Reports, images and videos related to the incident.
              </p>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};
