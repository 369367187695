import { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { PieChart, Show, Tooltip } from '@nirvana/ui-kit';

import { RiskScoreChartRecord } from 'src/helpers';
import LockEye from 'src/assets/icons/lock-eye.svg?react';
import ConnectTspModal from 'src/components/connect-tsp-modal';

type RiskScoreProps = {
  data: Array<RiskScoreChartRecord>;
  isLoading: boolean;
  isTelematicsConnected: boolean;
  isTRSDisabled: boolean;
};

export default function RiskScore({
  data,
  isLoading,
  isTelematicsConnected,
  isTRSDisabled,
}: RiskScoreProps) {
  const [showTelematicsModal, setShowTelematicsModal] = useState(false);

  function handleShowTelematicsModal() {
    setShowTelematicsModal(true);
  }

  function handleCloseTelematicsModal() {
    setShowTelematicsModal(false);
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center border rounded-lg">
        <CircularProgress size={32} />
      </div>
    );
  }

  return (
    <Show when={!isTRSDisabled}>
      <div className="relative p-4 overflow-hidden border rounded-lg">
        <Show when={!isTelematicsConnected}>
          <div className="absolute inset-0 z-10 flex flex-col items-center justify-center backdrop-blur-md">
            <LockEye className="mb-6" />
            <p className="mb-2 text-xl">
              Unlock your Vehicle&apos;s Nirvana Safety Score distribution
            </p>
            <p className="mx-20 mb-6 text-xs font-normal leading-5 text-center text-text-hint">
              Connect telematics to see the the distribution of your vehicles
              based on their Nirvana Safety Scores
            </p>
            <span>
              <Button variant="contained" onClick={handleShowTelematicsModal}>
                Connect
              </Button>
            </span>
            <ConnectTspModal
              open={showTelematicsModal}
              onClose={handleCloseTelematicsModal}
            />
          </div>
        </Show>

        <div className="flex items-center mb-4 text-base text-text-primary">
          <p className="mr-2 font-bold">Nirvana Safety Score Distribution</p>
          <Tooltip
            placement="right"
            title="The Nirvana Safety Score is based on predictive models using more than 3 billion miles of driving data to determine the likelihood of accidents compared to other fleets."
          >
            <button className="text-primary-main">
              <HiOutlineInformationCircle />
            </button>
          </Tooltip>
        </div>
        <div className="flex items-center justify-center h-64">
          <div className="w-1/2 h-full">
            <PieChart
              data={data}
              dataKey="count"
              innerRadius="60%"
              hideTooltip
            />
          </div>

          <div className="grid w-1/2 grid-cols-1 gap-4 text-xs text-gray-700">
            <div className="flex justify-between px-3 py-2 rounded bg-primary-extraLight text-text-hint">
              <p>Score</p>
              <p># of vehicles</p>
            </div>
            {data.map(({ name, fill, count, percentage }, index) => (
              <div
                key={name}
                className="grid items-center grid-cols-8 px-3 pr-5"
                data-cy={`riskScoreBucket${index}`}
              >
                <div
                  className="w-4 h-4 rounded"
                  style={{ backgroundColor: fill }}
                />
                <div className="flex items-center col-span-5 space-x-1">
                  <span>{name}</span>
                  <Show when={name === 'Inconclusive'}>
                    <Tooltip title="Inconclusive indicates we may not have enough data to calculate a reliable score">
                      <span>
                        <HiOutlineInformationCircle />
                      </span>
                    </Tooltip>
                  </Show>
                </div>
                <p className="text-sm font-semibold tabular-nums">
                  {count < 10 ? '0' + count : count}
                </p>
                <p className="font-normal tabular-nums text-text-hint">
                  ({percentage})%
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Show>
  );
}
